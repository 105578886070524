import instance from "./axios-client"

const organizationId = localStorage.getItem('tenant')
const API_URL = `/v1/organizations/${organizationId}/dashboard`

class CampaignService {
  async fetchDashboardOverview(data) {
    return await instance
      .get(API_URL,{ params: data })
      .then(response => {
        return response.data
      })
  }

  async fetchCampaignDropdownList() {
    return await instance
      .get(`${API_URL}/ddl-campaigns`)
      .then(response => {
        return response.data
      })
  }

  async fetchCampaignAnalysis(campaignId, data) {
    return await instance
      .get(`${API_URL}/campaigns/${campaignId}`, { params: data })
      .then(response => {
        return response.data
      })
  }

  async fetchCreatorInfluenceAnalysis(data) {
    return await instance
      .get(`${API_URL}/creator-influence-analysis`, { params: data })
      .then(response => {
        return response.data
      })
  }

  async fetchRecentCampaigns(data) {
    return await instance
      .get(`${API_URL}/recent-campaigns`,{ params: data })
      .then(response => {
        return response.data
      })
  }

  async fetchRecentReviews(data) {
    return await instance
      .get(`${API_URL}/recent-reviews`,{ params: data })
      .then(response => {
        return response.data
      })
  }
}

export default new CampaignService()
