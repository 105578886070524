import instance from "./axios-client"

const organizationId = localStorage.getItem('tenant')
const API_URL = `/v1/organizations/${organizationId}/campaigns`

class CampaignService {
  async fetchCampaigns(data) {
    return await instance
      .get(API_URL,{ params: data })
      .then(response => {
        return response.data
      });
  }

  async fetchCampaign(id) {
    return await instance
      .get(`${API_URL}/${id}`)
      .then(response => {
        return response.data
      });
  }

  async createCampaign(data) {
    return await instance
      .post(API_URL, data)
      .then(response => {
        return response.data
      })
  }

  async createDraftCampaign(data) {
    return await instance
      .post(`${API_URL}/draft`, data)
      .then(response => {
        return response.data
      })
  }

  async fetchCampaignReviews(id, data) {
    return await instance
      .get(`${API_URL}/${id}/reviews`,{ params: data })
      .then(response => {
        return response.data
      });
  }

  async cancelCampaign(id) {
    return await instance
      .delete(`${API_URL}/${id}/cancel`)
      .then(response => {
        return response.data
      })
  }

  async editCampaign(id) {
    return await instance
      .get(`${API_URL}/${id}`)
      .then(response => {
        return response.data
      })
  }

  async updateCampaign(id) {
    return await instance
      .put(`${API_URL}/${id}`)
      .then(response => {
        return response.data
      })
  }

  async deleteCampaign(data) {
    return await instance
      .delete(`${API_URL}`, data)
      .then(response => {
        return response.data
      })
  }
}

export default new CampaignService()
