import instance from "./axios-client"

const API_URL = `/v1`

class CommonService {
  async fetchOrganizationReviews(id, data) {
    return await instance
      .get(`${API_URL}/organizations/${id}/reviews`,{ params: data })
      .then(response => {
        return response.data
      })
  }
}

export default new CommonService()
