import { ref, nextTick } from "vue"

export default () => {
  const passwordFieldType = ref("password")
  const password = ref(null)

  async function onTogglePasswordVisibility() {
    passwordFieldType.value = passwordFieldType.value === "password" ? "text" : "password"
    await nextTick(() => {
      password.value.$el.focus()
    })
  }

  return {
    password,
    passwordFieldType,
    onTogglePasswordVisibility
  }
}
