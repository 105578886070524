<template>
  <!-- BEGIN: Setup Account Page -->
  <section>
    <div class='bg-white'>
      <!-- BEGIN: Application Logo -->
      <nav class='fixed flex items-center justify-between w-full pl-4 pt-2 h-20 box-border z-10'>
        <a href='/' class='-intro-x flex items-center text-title lg:text-white' rel='noopener noreferrer'>
          <img
            :alt="$t('app.title')"
            class="w-24 m-auto pb-2"
            src="@/assets/images/logo.jpeg"
          />
          <application-title />
        </a>
      </nav>
      <!-- END: Application Logo -->
      <!-- BEGIN: Wizard -->
      <!-- BEGIN: Wizard Steps -->
      <div class='-intro-x hidden lg:flex relative h-screen flex-col justify-center pl-8 float-left box-border' style='width: 430px'>
        <div class='form-wizard-progress'>
          <h6 class='wizard-progress-title'>
            {{ $t('auth.wizard.set_up_your_account', {title: $t('app.title')}) }}
          </h6>
          <div class='form-progress-item' v-for="(tab, index) in tabs"
               :class="{ 'active': tab.isActive, 'complete': tab.isCompleted, 'dirty': tab.isDirty }" v-bind:key="tab.title">
            <CircleIcon v-if="!tab.isCompleted"></CircleIcon>
            <CheckCircleIcon v-if="tab.isCompleted"></CheckCircleIcon>
            <div class="line"></div>
            <div class="text cursor-pointer" @click="onStepChange(index)">
              <h6>{{ tab.title.includes('auth.') ? $t(tab.title, {title: $t('app.title') }) : tab.title }}</h6>
              <p>{{ tab.description.includes('auth.') ? $t(tab.description) : $t(tab.description) }}</p>
            </div>
          </div>

          <!-- TODO: TBD
          <div class="action-progress-item">
              <UserPlusIcon class="icon"></UserPlusIcon>
              <span class="text">Collaborate with your brands</span>
          </div>
          -->
        </div>
      </div>
      <!-- END: Wizard Steps -->
      <!-- BEGIN: Wizard Content -->
      <!-- eslint-disable -->
      <div class="bg-theme-37">
        <div class="h-screen flex flex-col justify-center items-center bg-white rounded-none md:rounded-l-xl box-border px-5">
          <vee-form class="w-full lg:w-4/5 xl:w-1/2"
                    :validation-schema="currentSchema"
                    @submit="onNextStep"
                    v-slot="{ handleSubmit, errors }"
          >
            <!-- Wizard: Step One -->
            <div class="intro-y" v-show="onCurrentStep === 0">
              <h1 class="mb-2 text-2xl font-black tracking-tighter text-title md:text-4xl title-font"> {{ $t('auth.wizard.welcome_to', {'title': ''}) }}
                <span class="inline-block px-2 py-1 relative text-white bg-theme-36" style="border-radius: 91% 9% 90% 10% / 29% 82% 18% 71%">{{ $t('app.title') }}</span>
              </h1>
              <p class="mb-8 text-lg leading-relaxed text-left text-description"
                 v-html='$t("auth.wizard.welcome_to_msg")' />
              <!-- Organization Logo -->
              <div class="form-group block justify-between mb-5">
                <div class="my-5 text-center">
                  <div class="relative flex mx-auto w-32 h-32 mb-2 border border-gray-300 rounded-full relative mb-4">
                    <img id="organization-logo"
                         class="object-cover w-full bg-gray-200 rounded-full"
                         :src="organizationLogoUrl"
                         data-action="zoom"
                    >
                    <span class="absolute flex items-center justify-center top-0 bottom-0 left-0 right-0"
                          v-if="!organizationLogoUrl"
                    >
                      {{ $t('button.browse_organization_logo') }}
                    </span>
                    <Tippy for="fileInput"
                           type="button"
                           class="absolute bottom-0 right-0 items-center border p-2 rounded-full bg-primary-1 hover:bg-opacity-90 cursor-pointer"
                           tag="label"
                           :content="$t('button.browse_organization_logo')"
                           href="javascript:"
                           role="button"
                           aria-controls="content"
                           aria-selected="true"
                    >
                      <CameraIcon class="w-5 h-5 text-white -mt-0.5" />
                    </Tippy>
                  </div>
                  <input name="organizationLogo"
                         id="fileInput"
                         accept="image/*"
                         class="hidden"
                         type="file"
                         @change="handleImage"
                  >
                  <error-field v-if="validate.organizationLogo">
                    {{ $t('validation.organization_logo') }}
                  </error-field>
                </div>
              </div>
              <!-- Organization Name -->
              <div class="form-group block justify-between mb-5">
                <input-label>
                  {{ $t('auth.wizard.organization_name') }}
                </input-label>
                <vee-field id='organization-name'
                           class="custom-input-field"
                           :class="{'border-red-600': validate.organizationName }"
                           name="organizationName"
                           type="text"
                           :placeholder="$t('placeholder.organization_name')"
                           v-model="form.organizationName"
                           v-on:input="handleOrganizationUrl"
                           autocomplete="false"
                />
                <error-field v-if="validate.organizationName">
                  {{ validate.organizationName }}
                </error-field>
              </div>
              <!-- Organization Url -->
              <div class="form-group block justify-between mb-5"
                   v-show="showOrganizationUrl"
              >
                <input-label>
                  <span>
                    {{ $t('auth.wizard.organization_url') }}
                    <Tippy tag="span"
                           class=""
                           :content="$t('auth.wizard.organization_url_info')"
                    >
                      <fontawesome-icon icon="question-circle" class="fa-w-14 dark:text-gray-300" />
                    </Tippy>
                  </span>
                </input-label>
                <div class="mt-2 mb-1 relative rounded-md">
                  <div class="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                    <span class="text-description text-opacity-75 sm:text-sm">
                      {{ appURL }}
                    </span>
                  </div>
                  <vee-field class="custom-input-field" style="padding-left: 14.5rem;"
                             :class="{'border-red-600': validate.organizationUrl}"
                             id="organization-url"
                             name="organizationUrl"
                             type="text"
                             v-model="form.organizationUrl"
                             @blur="onUrlFormatter"
                             @input="onUrlFormatter"
                  />
                </div>
                <error-field v-if="validate.organizationUrl">
                  {{ validate.organizationUrl }}
                </error-field>
              </div>

              <!-- Go to Next Step -->
              <button-primary type="submit" class='md:w-56 mt-2' :isLoading='isLoading'>
                {{ $t('button.next_step') }}
              </button-primary>
            </div>
            <!-- Wizard: Step Two -->
            <div class="intro-y" v-show="onCurrentStep === 1">
              <h1 class="mb-8 text-2xl font-black tracking-tighter text-title md:text-3xl title-font">
                {{ $t('auth.wizard.set_up_your_profile') }}
              </h1>
              <!-- Full Name -->
              <div class="form-group block justify-between mb-5">
                <label class="text-title font-semibold" v-html="$t('auth.full_name')" />
                <vee-field
                  id='full-name'
                  class="custom-input-field"
                  :class="{'border-red-600': errors.fullName }"
                  name="fullName"
                  type="text"
                  :placeholder="$t('placeholder.organization_name')"
                  v-model="form.fullName"
                  autocomplete='name'
                />
                <error-message class="intro-y text-xs text-red-600" name="fullName" />
              </div>
              <!-- Password -->
              <div class="form-group block justify-between mb-5">
                <label class="relative block text-title font-semibold dark:text-gray-200">
                  {{ $t('auth.password') }}
                  <div class="absolute grid grid-cols-12 gap-3 h-1 mt-3 top-0 right-2">
                    <div
                      v-for="(indicator, index) in passwordIndicators"
                      v-bind:key="indicator"
                      class="col-span-3 h-full rounded dark:bg-dark-2"
                      :class="{ 'bg-gray-300 dark:bg-dark-2': passwordScore <= index + 1,
                                'bg-theme-10' : passwordScore >= index + 1 && passwordScore !== 0
                              }"
                    ></div>
                  </div>
                </label>
                <vee-field class="custom-input-field"
                           :class="{'border-red-600': errors.password }" id="password"
                           name="password"
                           type="password"
                           :placeholder="$t('placeholder.password')"
                           v-model="form.password"
                           @input="onPasswordStrengthChecker"
                           autocomplete='current-password'
                />
                <error-message class="intro-y text-xs text-red-600" name="password" />
              </div>
              <!-- Phone -->
              <div class="form-group block justify-between mb-5">
                <label class="text-title font-semibold" v-html="$t('auth.phone_number')" />
                <vee-field type='hidden' v-model='form.phone' name='phoneNumber'/>
                <vue-tel-input
                  class="form-control p-0 flex mt-2 mb-1 border custom-input-shadow"
                  :class="{'border-red-600': validate.phone || errors.phoneNumber }"
                  :value="formattedPhone"
                  @keypress="onOnlyNumber"
                  @validate='onPhoneValidation'
                >
                  <template v-slot:arrow-icon>
                    <svg class="flex-none w-3 h-3 transition transform" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                  </template>
                </vue-tel-input>
                <error-message class="intro-y text-xs text-red-600" name="phoneNumber" v-if="!validate.phone"/>
                <span class="intro-y text-xs text-primary-3 d-block mt-1" v-if="validate.phone">
                  {{ $t('validation.invalid_phone') }}
                </span>
              </div>
              <!-- Action -->
              <div class="flex items-center justify-between">
                <!-- Back to Previous Step -->
                <span class="w-full inline-flex items-center lg:hidden mt-2 mr-20 cursor-pointer text-title dark:text-gray-200"
                      @click="onPrevStep"
                >
                  <ArrowLeftCircleIcon class="mr-2"/>
                  {{ $t('button.back') }}
                </span>
                <!-- Go to Next Step -->
                <button-primary type="submit" class='md:w-56' :isLoading='isLoading'>
                  {{ $t('button.create_account') }}
                </button-primary>
              </div>
              <i18n-t keypath="auth.wizard.term_and_condition" tag="p" class="mt-10">
                <template v-slot:button>
                  <b>{{ $t('button.create_account') }}</b>
                </template>
                <template v-slot:tnc>
                  <a :href='tncURL' class="cursor-pointer text-theme-35" target="_blank" rel="noopener noreferrer">
                    {{ $t('auth.term_and_condition') }}
                  </a>
                </template>
              </i18n-t>
            </div>
            <!-- Wizard: Step Three -->
            <div class="intro-y" v-show="onCurrentStep === 2">
              <h1 class="font-black tracking-tighter text-title md:text-3xl title-font mb-2">
                {{ $t('auth.wizard.step_three_title') }}
              </h1>
              <p class="mb-8 text-lg leading-relaxed text-left text-description"> {{ $t('auth.wizard.step_three_msg_1') }} </p>

              <!-- Country Selection -->
              <div class="form-group block justify-between mb-5">
                <label class="block mb-2 text-sm font-medium text-title dark:text-gray-200">
                  {{ $t('auth.wizard.country') }}
                </label>
                <multiselect
                  v-model="form.country"
                  :options="countries"
                  mode="single"
                  :canDeselect="false"
                  :canClear="false"
                  :placeholder="$t('dropdown.choose_a_country')"
                  :searchable="true"
                  autocomplete='none'
                  :noResultsText="$t('app.no_result_found')"
                  :style="[validate.country ? { 'border': '1px solid rgba(220, 38, 38, 1)'} : '' ]"
                >
                </multiselect>
                <error-field v-if="validate.country">
                  {{ $t('validation.please_choose_country') }}
                </error-field>
              </div>

              <!-- Business Category Selection -->
              <div class="form-group block justify-between mb-5">
                <label class="block mb-2 text-sm font-medium text-title dark:text-gray-200">
                  {{ $t('auth.wizard.business_category') }}
                </label>
                <multiselect v-model="form.businessCategory"
                             class="border"
                             :options="businessCategories"
                             mode="single"
                             :canDeselect="false"
                             :canClear="false"
                             :placeholder="$t('dropdown.choose_a_business_category')"
                             :noResultsText="$t('app.no_result_found')"
                             :style="[validate.businessCategory ? { 'border': '1px solid rgba(220, 38, 38, 1)'} : '' ]"
                             trackBy="value"
                             label="label"
                >
                  <template v-slot:singlelabel="{ value }">
                    <span style='margin: 0 auto 0 0;'>{{ $t(value.label) }}</span>
                  </template>
                  <template v-slot:option="{ option }">
                    {{ $t(option.label)}}
                  </template>
                </multiselect>
                <error-field v-if="validate.businessCategory">
                  {{ $t('validation.please_choose_category') }}
                </error-field>
              </div>

              <!-- Industry Selection -->
              <div class="form-group block justify-between mb-5">
                <label class="block mb-2 text-sm font-medium text-title dark:text-gray-200">
                  {{ $t('auth.wizard.industry_category') }}
                </label>
                <div class="grid grid-cols-12 gap-2 mb-3">
                  <selectable-radio-icon
                    v-for="(industry, key) in industries"
                    name="industries"
                    :selection-name="$t(industry.key)"
                    :slug="industry.slug"
                    v-model="form.industry"
                    :key="key"
                    fontClass="text-sm"
                  >
                    <img
                      class='w-6 h-6 mr-3'
                      :src='require("@/assets/images/category/" + industry.icon_path)'
                      :alt='industry.slug'
                    >
                  </selectable-radio-icon>
                </div>
                <error-field v-if="validate.industry">
                  {{ $t('validation.please_choose_one') }}
                </error-field>
              </div>

              <!-- Action -->
              <div class="flex items-center justify-between">
                <!-- Back to Previous Step -->
                <span class="w-full inline-flex items-center lg:hidden mt-2 mr-20 cursor-pointer text-title dark:text-gray-200" @click="onPrevStep">
                  <ArrowLeftCircleIcon class="mr-2"/>
                  {{ $t('button.back') }}
                </span>
                <!-- Go to Next Step -->
                <button-primary type="submit" class='md:w-56 mt-2' :isLoading='isLoading'>
                  {{ $t('button.next_step') }}
                </button-primary>
              </div>
            </div>
            <!-- Wizard: Step Three -->
            <div class="intro-y" v-show="onCurrentStep === 3">
              <div class="form-group block justify-between mb-5">
                <h1 class="mb-2 text-2xl font-black tracking-tighter text-black md:text-3xl title-font">
                  {{ $t('auth.wizard.step_four_msg_2') }}
                </h1>
                <div class="grid grid-cols-12 gap-2 mt-10 mb-3">
                  <selectable-radio-big-icon name="usages"
                                             selection-name="trial"
                                             :description="$t('auth.wizard.step_four_msg_3')"
                                             v-model="form.usage"
                  >
                    <usage-one />
                  </selectable-radio-big-icon>
                  <selectable-radio-big-icon name="usages"
                                             selection-name="join_us"
                                             :description="$t('auth.wizard.step_four_msg_4')"
                                             v-model="form.usage"
                  >
                    <usage-two />
                  </selectable-radio-big-icon>
                  <div id="file-type-validation" class="intro-y col-span-12" v-show="form.usage === 'join_us'">
                    <div class="preview">
                      <Dropzone ref-key="dropzoneValidationRef"
                                :options="dropzoneConfig"
                                class="dropzone text-center"
                      >
                        <UploadIcon class="w-10 h-10 mb-2"/>
                        <div class="mb-1 text-gray-700 text-base font-medium">
                          {{ $t('app.message.upload') }}
                        </div>
                        <div class="text-gray-600">
                          {{ $t('auth.wizard.business_certificate_upload') }}
                        </div>
                      </Dropzone>
                    </div>
                  </div>
                </div>
                <error-field v-if="validate.usage">
                  {{ $t('validation.please_choose_one') }}
                </error-field>
                <error-field v-if="validate.certificateFiles">
                  {{ $t('validation.please_upload_business_certificate_to_verify') }}
                </error-field>
              </div>
              <!-- Action -->
              <div class="flex items-center justify-between">
                <!-- Back to Previous Step -->
                <span class="w-full inline-flex items-center lg:hidden mt-2 mr-20 cursor-pointer text-title dark:text-gray-200"
                      @click="onPrevStep"
                >
                  <ArrowLeftCircleIcon class="mr-2"/>
                  {{ $t('button.back') }}
                </span>
                <!-- Go to Next Step -->
                <button-primary type="submit"
                                class='md:w-56'
                                :isLoading='isLoading'
                >
                  {{ $t('button.next_step') }}
                </button-primary>
              </div>
            </div>
          </vee-form>
        </div>
      </div>
      <!-- eslint-enable -->
      <!-- END: Wizard -->
    </div>
  </section>
  <!-- END: Account Setup Page -->
</template>

<script setup>
import { defineAsyncComponent, ref, reactive, watch, computed, provide, onMounted } from 'vue'
import SelectableRadioBigIcon from "../../components/radio/SelectableRadioBigIcon.vue"
import SelectableRadioIcon from "../../components/radio/SelectableRadioIcon.vue"
import '@/assets/css/vue3-tel-input.css'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from '@/store'
import businessCategoryJson from "../../assets/json/business-category.json"
import industryCategoryJson from "../../assets/json/industry-category.json"
import { country } from '@/libs/country'
import Multiselect from '@vueform/multiselect'
import '@vueform/multiselect/themes/default.css'
import i18n from '@/i18n'
import useUploadTempFile from "../../composables/useUploadTempFile"
import useKeyboard from "../../composables/useKeyboard"
import useSetupAccountWizard from "../../composables/useSetupAccountWizard"
import useValidation from "../../composables/useValidation"

const UsageOne = defineAsyncComponent(() => import("../../global-components/svg/UsageOne.vue"))
const UsageTwo = defineAsyncComponent(() => import("../../global-components/svg/UsageTwo.vue"))
const ErrorField = defineAsyncComponent(() => import("../../components/error/ErrorField.vue"))

const { t } = i18n.global
const { onOnlyNumber } = useKeyboard()
const router = useRouter()
const route = useRoute()
const store = useStore()
const tncURL = process.env.VUE_APP_TERM_AND_CONDITION
const appURL = process.env.VUE_APP_MARKETPLACE_URL
const id = route.params.id
const businessCategories = ref(businessCategoryJson)
const industries = ref(industryCategoryJson)
const loggedIn = computed(() => store.state.auth.status.loggedIn)
const { fileUrl, filePath, handleImage } = useUploadTempFile()
const organizationLogoUrl = ref(fileUrl)
const formattedPhone = ref(null)
const { passwordScore, onPasswordStrengthChecker, onUrlFormatter } = useValidation()
const passwordIndicators = ref(4)
const {
  schema,
  isLoading,
  tabs,
  totalTabs,
  form,
  validate,
  onCurrentStep,
  setActiveStep,
  onStepChange,
  onPrevStep
} = useSetupAccountWizard()
const { onNameValidation } = useValidation()
const showOrganizationUrl = ref(false)

form.id = id
form.organizationLogo = filePath
const dropzoneValidationRef = ref()
const certificateFiles = ref([])
const dropzoneConfig = ref({
  url: process.env.VUE_APP_API_URL + "/v1/uploads",
  acceptedFiles: ".jpeg,.jpg,.png,.pdf",
  maxFilesize: 10, // MB
  maxFiles: 6,
  chunking: true,
  chunkSize: 10000000, // Bytes
  addRemoveLinks: true,
  init: function() {
    this.on('addedfile', function(file) {
      Object.assign(validate, {
        certificateFiles: false,
      })
    })
    this.on('removedfile', function(file) {
      const res = JSON.parse(file.xhr.response)
      certificateFiles.value = certificateFiles.value.filter(function(item) {
        return item !== res.filename
      })
    })
  },
})
watch(() => [
  form.organizationLogo,
  form.organizationName,
  form.organizationUrl,
  form.country,
  form.businessCategory,
  form.industry,
  form.usage,
], (
  [organizationLogoValue, organizationNameValue, organizationUrlValue, countryValue, businessCategoryValue, industryValue, usageValue],
) => {
  if(organizationLogoValue) {
    validate.organizationLogo = false
  }

  if(organizationNameValue) {
    validate.organizationName = onNameValidation(form.organizationName)
  }

  if(organizationUrlValue) {
    validate.organizationUrl = false
  }

  if(countryValue) {
    validate.country = false
  }

  if(businessCategoryValue) {
    validate.businessCategory = false
  }

  if(industryValue) {
    validate.industry = false
  }

  if(usageValue) {
    validate.usage = false
    validate.certificateFiles = false
  }
})

const currentSchema = computed(() => {
  return schema[onCurrentStep.value]
})

const countries = computed(() => {
  return country.list()
})

if (loggedIn.value) {
  router.push("/");
}

function onNextStep(values) {
  if (onCurrentStep.value === 0) {

    Object.assign(validate, {
      organizationName: onNameValidation(form.organizationName),
      organizationLogo: (form.organizationLogo === undefined || !form.organizationLogo),
    })
    if (Object.keys(validate).some(k => validate[k])) {
      return
    }

    if(!form.organizationUrl) {
      showOrganizationUrl.value = true
      validate.organizationUrl = t('validation.required')

      return
    }
    isLoading.value = true
    store.dispatch("auth/checkOrganizationUrl", form.organizationUrl).then(
      () => {
        isLoading.value = false
        validate.organizationUrl = false
        tabs.value[onCurrentStep.value].isCompleted = true
        tabs.value[onCurrentStep.value].title = form.organizationName
        onCurrentStep.value++
        setActiveStep(onCurrentStep.value)
      },
      (error) => {
        isLoading.value = false
        showOrganizationUrl.value = true
        validate.organizationUrl =
          (error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.data.message[0]) ||
          error.message ||
          error.toString()
      }
    )

    return
  }
  if (onCurrentStep.value === 1) {
    tabs.value[onCurrentStep.value].title = t('auth.wizard.step_two_msg_2', { name: form.fullName })
    tabs.value[onCurrentStep.value].description = t('auth.wizard.welcome_to', { title: t('app.title') }) + '!'
  }

  if (onCurrentStep.value === 2) {
    Object.assign(validate, {
      country: (form.country === undefined || !form.country),
      businessCategory: (form.businessCategory === undefined || !form.businessCategory),
      industry: (form.industry === undefined || !form.industry)
    })

    if (Object.keys(validate).some(k => validate[k])) {
      return
    }

    form.country = form.countryCode
    tabs.value[onCurrentStep.value].description = 'auth.wizard.step_three_msg_2'
  }
  if (onCurrentStep.value === 3) {

    if (form.usage === "join_us") {
      form.certificateFiles = certificateFiles.value
    }

    Object.assign(validate, {
      usage: (form.usage === undefined || !form.usage),
      certificateFiles: (form.usage === "join_us") && (form.certificateFiles === undefined || !form.certificateFiles || Object.keys(form.certificateFiles).length === 0)
    })

    if (Object.keys(validate).some(k => validate[k])) {
      return
    }

    store.dispatch("auth/complete", form).then(
      () => {
        router.push('/')
      },
      (error) => {
        isLoading.value = false
        validate.organizationUrl =
          (error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.data.message[0]) ||
          error.message ||
          error.toString()
      }
    )
    return
  }

  if (Object.keys(validate).some(k => validate[k])) {
    return
  }

  tabs.value[onCurrentStep.value].isCompleted = true
  onCurrentStep.value++;
  setActiveStep(onCurrentStep.value)
}

provide("bind[dropzoneValidationRef]", el => {
  dropzoneValidationRef.value = el;
})

onMounted(() => {
  const elDropzoneValidationRef = dropzoneValidationRef.value
  elDropzoneValidationRef.dropzone.on("success", ( response ) => {
    const res = JSON.parse(response.xhr.response)
    certificateFiles.value.push(res.filename)
  });
  elDropzoneValidationRef.dropzone.on("error", ( file ) => {
    const res = JSON.parse(file.xhr.response)
    const error = res.error
    const message = (error.response &&
        error.response.data &&
        error.response.data.message &&
        error.response.data.message[0]) ||
      error.message ||
      error.toString()

    alert(message)
  });

  totalTabs.value = tabs.value.length
})

let phoneInputTimer = null
function onPhoneValidation(phone, phoneObject, input) {

  if(phone.valid === undefined) {
    return;
  }

  if (phoneInputTimer) {
    clearTimeout(phoneInputTimer);
    phoneInputTimer = null;
  }
  phoneInputTimer = setTimeout(() => {
    if(phone?.valid) {
      validate.phone = false
      form.idd = parseInt(phone?.countryCallingCode)
      form.countryCode = phone?.countryCode
      form.phone = parseInt(phone?.nationalNumber)
    } else {
      validate.phone = true
    }
  }, 600);
}

function handleOrganizationUrl(event) {
  form.organizationUrl = onUrlFormatter(event)
}
</script>
