<template>
  <button class="btn btn-primary py-2.5 px-4 w-full h-10 font-semibold"
          :disabled='isLoading'>
    <spring-spinner
      :animation-duration="3000"
      :size="20"
      :color="spinnerColorHex"
      v-show="isLoading"
    />

    <span
      class='flex items-center'
      v-show='!isLoading'
    >
      <slot></slot>
      <svg class="hover-arrow" :style="'color:' + spinnerColorHex" width="10" height="10" viewBox="0 0 10 10" aria-hidden="true">
        <g fill-rule="evenodd">
          <path class="hover-arrow__linepath" d="M0 5h7"></path>
          <path class="hover-arrow__tippath" d="M1 1l4 4-4 4"></path>
        </g>
      </svg>
    </span>
  </button>
</template>

<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    spinnerColorHex: {
      type: String,
      default: '#ffffff'
    },
  }
}
</script>
