<template>
  <div class="breeding-rhombus-spinner" :style="spinnerStyle">
    <div class="rhombus" v-for="(rs, index) in rhombusesStyles" :key="index" :style="rs" :class="`child-${index + 1}`"></div>
    <div class="rhombus big" :style="bigRhombusStyle"></div>
  </div>
</template>

<script>
export default {
  name: 'BreedingRhombusSpinner',
  props: {
    animationDuration: {
      type: Number,
      default: 2000
    },
    size: {
      type: Number,
      default: 150
    },
    color: {
      type: String,
      default: '#fff'
    }
  },
  data () {
    return {
      animationBaseName: 'breeding-rhombus-spinner-animation-child',
      rhombusesNum: 8
    }
  },
  computed: {
    spinnerStyle () {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`
      }
    },
    rhombusStyle () {
      return {
        height: `${this.size / 7.5}px`,
        width: `${this.size / 7.5}px`,
        animationDuration: `${this.animationDuration}ms`,
        top: `${this.size / 2.3077}px`,
        left: `${this.size / 2.3077}px`,
        backgroundColor: this.color
      }
    },
    rhombusesStyles () {
      const rhombusesStyles = []
      const delayModifier = this.animationDuration * 0.05
      for (let i = 1; i <= this.rhombusesNum; i++) {
        rhombusesStyles.push(Object.assign({
          animationDelay: `${delayModifier * (i + 1)}ms`
        }, this.rhombusStyle))
      }
      return rhombusesStyles
    },
    bigRhombusStyle () {
      return {
        height: `${this.size / 3}px`,
        width: `${this.size / 3}px`,
        animationDuration: `${this.animationDuration}`,
        top: `${this.size / 3}px`,
        left: `${this.size / 3}px`,
        backgroundColor: this.color
      }
    }
  }
}
</script>

<style scoped>
</style>
