<template>
  <div>
    <div id="toast-notification-content"
         class="toastify-content toastify-content-ext hidden flex items-center"
    >
      <XCircleIcon class="text-theme-10"
                   v-if="response.success"
      />
      <XCircleIcon class="text-theme-24"
                   v-if="!response.success"
      />
      <div class="ml-4">
        <div class="font-medium">{{ response.message }}</div>
      </div>
    </div>
    <!-- BEGIN: Application Logo -->
    <a href="/" class="absolute top-0 flex items-center pt-8 pl-4 text-white" rel="noopener noreferrer">
      <img
        :alt="$t('app.title')"
        class="w-24 m-auto pb-2"
        src="@/assets/images/logo.jpeg"
      />
      <application-title />
    </a>
    <!-- END: Application Logo -->
    <!-- BEGIN: Email Verification Page -->
    <section class="relative flex items-center bg-primary" style='height: 95vh'>
      <div class="container flex flex-col px-5 py-24 mx-auto lg:items-center">
        <div class="flex flex-col w-full my-8 text-center">
          <img class="mt-14 mx-auto w-40" src="@/assets/images/mail.svg">
          <h1 class="mt-6 mb-8 text-lg font-semibold tracking-tighter text-white sm:text-2xl title-font"> {{ $t('auth.email_verification_title') }} </h1>
          <p class="mb-1.5 mx-auto text-lg leading-relaxed text-white lg:w-2/3">
            {{ $t('auth.code_sent_message') }}<b>{{ email }}</b>
          </p>
          <span class="text-white text-base leading-6 text-opacity-60">{{ $t('auth.code_sent_tip') }}</span>

          <!-- OTP input -->
          <p class="mt-10 mb-4 text-base text-white">{{ $t('auth.enter_code') }}</p>
          <OtpInput
            ref="otpInput0"
            input-classes="otp-input"
            v-bind:class="{ 'error': !response.success }"
            separator="-"
            :num-inputs="4"
            :should-auto-focus="true"
            @on-change="handleOnChange"
            @on-complete="handleOnComplete"
          />
        </div>

        <div class="flex justify-left lg:justify-center">
          <!-- Submission -->

          <button-primary class="bg-theme-36 text-primary-1 border-theme-36"
                          :disabled="!isCompleted || isLoading"
                          :isLoading="isLoading"
                          spinnerColorHex="#313131"
                          @click="handleVerify"
          >
            {{ $t('button.confirm_verification') }}
          </button-primary>
        </div>
      </div>
    </section>
    <!-- BEGIN: Email Verification Page -->
  </div>
</template>

<script setup>
import { ref, reactive } from "vue"
import { useStore } from "@/store"
import { useRouter } from "vue-router"
import OtpInput from "../../components/otp/OtpInput.vue"
import useNotification from "../../composables/useNotification.js"

const props = defineProps({
  email: {
    type: String
  }
})
const store = useStore()
const router = useRouter()
const { toastNotification } = useNotification()
const isLoading = ref(false)
const isCompleted = ref(false)
const response = reactive({
  "success": true,
  "message": null
})
const otpCode = ref(null)

function handleOnComplete(code) {
  isCompleted.value = true
  handleVerify(code)
}

function handleOnChange(code) {
  isLoading.value = false
  response.success = true
  response.message = ""
  otpCode.value = code
}

function handleVerify(code) {
  isLoading.value = true
  response.message = ""

  store.dispatch("auth/verify", {
    email: props.email,
    verification_code: otpCode.value,
  }).then(
    (data) => {
      isLoading.value = true
      response.success = true
      response.message = data.message

      router.push({ name: 'setup-account', params: { id: data.id } })
    },
    (error) => {
      isLoading.value = false
      response.success = false
      response.message =
        (error.response &&
          error.response.data &&
          error.response.data.message &&
          error.response.data.message[0]) ||
        error.message ||
        error.toString()
    }
  ).finally(() => {
    console.log(response)
    toastNotification()
  })
}
</script>
