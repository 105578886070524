export default () => {
  function onOpenMediaPost(url, name) {
    const width = 1000
    const height = 720
    const left = (screen.width / 2) - (width / 2)
    const top = (screen.height / 2) - (height / 2)
    return window.open(
      url + "/",
      name,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, resizable=no, " +
      "copyhistory=no, width=" + width + ", height=" + height + ", top=" + top + ", left=" + left
    )
  }

  return {
    onOpenMediaPost
  }
}
