import { ref, reactive, computed } from "vue"
import { useStore } from "@/store"
import { useRouter } from "vue-router"
import useNotification from "../composables/useNotification.js"

export default () => {
  const store = useStore()
  const router = useRouter()
  const isLoading = ref(false)
  const { toastNotification } = useNotification()
  const response = reactive({
    "success": true,
    "message": null
  })
  const forms = reactive({
    email: "",
    password: ""
  })

  function handleLogin(user) {
    isLoading.value = true

    store.dispatch("auth/login", user).then(
      () => {
        response.success = true
        router.push("/")
      },
      (error) => {
        isLoading.value = false
        response.success = false
        response.message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
      }
    ).finally(() => {
      if(!response.success) {
        toastNotification()
      }
    })
  }

  const isLoggedIn = computed(() => {
    return store.state.auth.status.isLoggedIn
  })

  if (isLoggedIn.value) {
    router.push("/")
  }

  return {
    forms,
    isLoading,
    response,
    handleLogin
  }
}
