import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VeeValidatePlugin from "./libs/validation"
import VueTelInput from "./libs/international-phone"
// import ImageFallBack from "./directives/image-fallback"
import VueLazyload from '@jambonn/vue-lazyload'
import { Skeletor } from 'vue-skeletor'
// import { createI18n } from 'vue-i18n'
// import en from './locales/en.json'
// import zhCn from './locales/zh-cn.json'
// import zhTw from './locales/zh-tw.json'
// import zhHk from './locales/zh-hk.json'
import globalComponents from './global-components'
import utils from './utils'
import './libs'
import moment from 'moment'
// import { setupI18n as i18n } from '@/i18n'
import i18n from './i18n'
import FontAwesomeIcon from "./utils/fontawesome"
// SASS Theme
import './assets/sass/app.scss'
import Highcharts from 'highcharts'
import VueHighcharts from 'vue3-highcharts'
import highchartsMore from 'highcharts/highcharts-more'
import PerfectScrollbar from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css'

// const i18n = createI18n({
//   fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
//   messages: {
//     en: en,
//     zh_cn: zhCn,
//     zh_tw: zhTw,
//     zh_hk: zhHk,
//   }
// })
//

i18n.global.locale = process.env.VUE_APP_I18N_LOCALE

const app = createApp(App);
const loadImage = require('./assets/images/fallback-image.svg')
const errorImage = require('./assets/images/fallback-image.svg')
app.config.productionTip = false
app.config.globalProperties.$moment = moment
app.component("fontawesome-icon", FontAwesomeIcon)
  .component(Skeletor.name, Skeletor)
  .use(VueHighcharts, {Highcharts})
  .use(i18n)
  .use(store)
  .use(router)
  .use(VeeValidatePlugin)
  .use(VueTelInput)
  .use(VueLazyload, {
    preLoad: 1.3,
    error: errorImage,
    loading: loadImage,
    attempt: 1
  })
  .use(PerfectScrollbar)
globalComponents(app)
utils(app)

app.config.productionTip = false
app.mount('#app')
