const state = () => {
  return {
    menu: [
      {
        icon: 'HomeIcon',
        pageName: 'top-menu-dashboard',
        title: 'nav.dashboard',
      },
      {
        icon: 'Volume2Icon',
        pageName: 'top-menu-campaign',
        title: 'nav.campaign'
      },
      {
        icon: 'DatabaseIcon',
        pageName: 'top-menu-creator',
        title: 'nav.creator_database'
      },
    ]
  }
}

// getters
const getters = {
  menu: state => state.menu
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
