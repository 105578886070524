import AuthService from '../../services/auth.service'

const user = JSON.parse(localStorage.getItem('user'))
const initialState = user
  ? { status: { isLoggedIn: true }, user }
  : { status: { isLoggedIn: false }, user: null }

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        user => {
          if (user.data.access_token) {
            localStorage.setItem('user', JSON.stringify(user.data))
            localStorage.setItem('organizations', JSON.stringify(user.data.organizations))
            localStorage.setItem('tenant', user.data?.organizations[0]?.id)
          }
          console.log(user.data)
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          localStorage.removeItem('user')
          localStorage.removeItem('organizations')
          localStorage.removeItem('tenant')

          return Promise.reject(error)
        }
      );
    },
    logout({ commit }) {
      localStorage.removeItem('user')
      localStorage.removeItem('organizations')
      localStorage.removeItem('tenant')

      commit('logout')
    },
    register({ commit }, user) {
      return AuthService.register(user).then(
        response => {
          commit('registerSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('registerFailure');
          return Promise.reject(error);
        }
      );
    },
    verify({ commit }, user) {
      return AuthService.verify(user).then(
        response => {
          commit('verifySuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('verifyFailure');
          return Promise.reject(error);
        }
      );
    },
    complete({ commit }, user) {
      return AuthService.complete(user).then(
        response => {
          commit('setupSuccess');
          return Promise.resolve(response.data);
        },
        error => {
          commit('setupFailure');
          return Promise.reject(error);
        }
      );
    },
    checkOrganizationUrl({ commit }, url) {
      return AuthService.checkOrganizationUrl(url).then(
        response => {
          // commit('verifySuccess');
          return Promise.resolve(response.data);
        },
        error => {
         // commit('verifyFailure');
          return Promise.reject(error);
        }
      );
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.isLoggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.isLoggedIn = false;
      state.user = null;
    },
    logout(state) {
      state.status.isLoggedIn = false;
      state.user = null;
    },
    registerSuccess(state) {
      state.status.isLoggedIn = false;
    },
    registerFailure(state) {
      state.status.isLoggedIn = false;
    },
    verifySuccess(state) {
      state.status.isLoggedIn = false;
    },
    verifyFailure(state) {
      state.status.isLoggedIn = false;
    },
    setupSuccess(state) {
      state.status.isLoggedIn = false;
      state.status.isSetupCompleted = true;
    },
    setupFailure(state) {
      state.status.isLoggedIn = false;
      state.status.isSetupCompleted = false;
    }
  }
};
