<template>
  <div>
    <div v-if="reviews"
         :class="`grid-cols-${ cols }` + ` sm:grid-cols-${ smCols }` + ` md:grid-cols-${ mdCols }` + ` lg:grid-cols-${ lgCols }` + ` xl:grid-cols-${ xlCols }`"
         class="grid gap-2"
    >
      <div v-for="(review, key) in reviews"
           :key="key"
           class="profile-card w-full mx-auto overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 cursor-pointer"
           @click.prevent="onOpenMediaPost(review.media.links.self, review.profile.full_name ? review.profile.full_name : review.profile.username)"
      >
        <div class="flex items-center border-b border-gray-200 dark:border-dark-5 p-2"
             @click.stop="onCreatorProfile(review.profile.creator.links.self)"
        >
          <div class="w-10 h-10 flex-none image-fit">
            <img v-lazy="review.profile.profile_picture_url"
                 :alt="review.profile.full_name ? review.profile.full_name : review.profile.username" class="rounded-full">
          </div>
          <div class="w-full ml-3 mr-auto">
            <a class="font-medium block truncate"
               href="javascript:"
            >
              {{ review.profile.full_name ? review.profile.full_name : review.profile.username }}
            </a>
            <div class="flex text-gray-600 truncate text-xs mt-0.5">
              {{ review.media.created_at }}
            </div>
          </div>
        </div>
        <div class="relative">
          <img v-lazy="review.media.links.image_thumbnail" :alt="review.media.caption"
               :class="{'opacity-75' :review.media.type === 'video'}"
               class="object-fill w-full h-auto"
          >
          <PlayCircleIcon v-if="review.media.type === 'video'"
                          class="absolute top-0 bottom-0 right-0 left-0 m-auto w-12 h-12 text-theme-25" />
        </div>
        <div class="p-4">
          <perfect-scrollbar>
            <p class="h-28 py-2 text-xs md:text-sm text-description dark:text-gray-400">
              {{ review.media.caption }}
            </p>
          </perfect-scrollbar>
          <div class="profile-card-meta bg-white flex flex-col justify-around relative w-full pt-4">
            <div class="profile-card-meta-details flex items-center justify-around">
              <div class="text-center">
                <a :href="review.media.links.self" class="block">
                  <span class="block -mb-1 text-sm md:text-lg text-primary-1">
                    {{ $h.abbreviateNumber(review.media.likes_count) }}
                  </span>
                  <span class="text-opacity-50 lg:text-description tracking-wide text-2xs md:text-xs">
                    {{ $t("creator.likes") }}
                  </span>
                </a>
              </div>
              <div class="w-px h-6 bg-gray-400"></div>
              <div class="text-center">
                <a :href="review.media.links.self" class="block">
                  <span class="block -mb-1 text-sm md:text-lg text-primary-1">
                    {{ $h.abbreviateNumber(review.media.comments_count) }}
                  </span>
                  <span class="text-opacity-50 lg:text-description tracking-wide text-2xs md:text-xs">
                    {{ $t("creator.comments") }}
                  </span>
                </a>
              </div>
              <div class="w-px h-6 bg-gray-400"></div>
              <div class="text-center">
                <span class="block -mb-1 text-sm md:text-lg text-primary-1">
                  {{ review.media.engagement_rate }}%
                </span>
                <span class="text-opacity-50 lg:text-description tracking-wide text-2xs md:text-xs">
                  {{ $t("creator.engagement_rate") }}
                </span>
              </div>
            </div>
            <a
              class="card-view-button absolute w-full bg-grey-panel font-bold border border-opacity-25 rounded-full py-2 mx-auto left-0 right-0 lg:w-11/12 hover:border-theme-21 hover:text-theme-21 hidden lg:flex lg:items-center lg:justify-center text-sm mb-1"
              @click.prevent="onOpenMediaPost(review.media.links.self, review.profile.full_name ? review.profile.full_name : review.profile.username)"
            >
              <span>{{ $t("button.view") }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CreatorReviewSkeleton from "../../components/skeleton/CreatorReviewSkeleton.vue"
import useCreatorDatabase from "../../composables/useCreatorDatabase"
import useMediaPost from "../../composables/useMediaPost"

defineProps({
  reviews: {
    type: Object
  },
  isReviewLoading: {
    type: Boolean
  },
  xlCols: {
    type: Number,
    default: 6
  },
  lgCols: {
    type: Number,
    default: 4
  },
  mdCols: {
    type: Number,
    default: 3
  },
  smCols: {
    type: Number,
    default: 2
  },
  cols: {
    type: Number,
    default: 1
  }
})

const { onOpenMediaPost } = useMediaPost()
const { onCreatorProfile } = useCreatorDatabase()
</script>
