import { createRouter, createWebHistory } from 'vue-router'
// import { loadLocaleMessages, setI18nLanguage, setupI18n as i18n, SUPPORT_LOCALES } from '@/i18n'

import SideMenu from '../layouts/side-menu/Main.vue'
import TopMenu from '../layouts/top-menu/Main.vue'

// Auth
import Login from '../views/auth/Login.vue'
import Register from '../views/auth/Register.vue'
import ForgotPassword from '../views/auth/ForgotPassword.vue'
import EmailVerification from "../views/auth/EmailVerification.vue"
import SetupAccount from "../views/auth/SetupAccount.vue"
import DashboardOverview2 from '../views/dashboard-overview-2/Main.vue'
import WeeklyCampaignReview from '../views/general/WeeklyCampaignReview.vue'

// import Inbox from '../views/inbox/Main.vue'
// import FileManager from '../views/file-manager/Main.vue'
// import PointOfSale from '../views/point-of-sale/Main.vue'
// import Chat from '../views/chat/Main.vue'
// import Post from '../views/post/Main.vue'
// import Calendar from '../views/calendar/Main.vue'
// import CrudDataList from '../views/crud-data-list/Main.vue'
// import CrudForm from '../views/crud-form/Main.vue'
// import UsersLayout1 from '../views/users-layout-1/Main.vue'
// import UsersLayout2 from '../views/users-layout-2/Main.vue'
// import UsersLayout3 from '../views/users-layout-3/Main.vue'
// import ProfileOverview1 from '../views/profile-overview-1/Main.vue'
// import ProfileOverview2 from '../views/profile-overview-2/Main.vue'
// import ProfileOverview3 from '../views/profile-overview-3/Main.vue'
// import WizardLayout1 from '../views/wizard-layout-1/Main.vue'
// import WizardLayout2 from '../views/wizard-layout-2/Main.vue'
// import WizardLayout3 from '../views/wizard-layout-3/Main.vue'
// import BlogLayout1 from '../views/blog-layout-1/Main.vue'
// import BlogLayout2 from '../views/blog-layout-2/Main.vue'
// import BlogLayout3 from '../views/blog-layout-3/Main.vue'
// import PricingLayout1 from '../views/pricing-layout-1/Main.vue'
// import PricingLayout2 from '../views/pricing-layout-2/Main.vue'
// import InvoiceLayout1 from '../views/invoice-layout-1/Main.vue'
// import InvoiceLayout2 from '../views/invoice-layout-2/Main.vue'
// import FaqLayout1 from '../views/faq-layout-1/Main.vue'
// import FaqLayout2 from '../views/faq-layout-2/Main.vue'
// import FaqLayout3 from '../views/faq-layout-3/Main.vue'
import ErrorPage from '../views/error-page/Main.vue'
// import UpdateProfile from '../views/update-profile/Main.vue'
// import ChangePassword from '../views/change-password/Main.vue'
// import RegularTable from '../views/regular-table/Main.vue'
// import Tabulator from '../views/tabulator/Main.vue'
// import Accordion from '../views/accordion/Main.vue'
// import Button from '../views/button/Main.vue'
// import Modal from '../views/modal/Main.vue'
// import SlideOver from '../views/slide-over/Main.vue'
// import Notification from '../views/notification/Main.vue'
// import Alert from '../views/alert/Main.vue'
// import ProgressBar from '../views/progress-bar/Main.vue'
// import Tooltip from '../views/tooltip/Main.vue'
// import Dropdown from '../views/dropdown/Main.vue'
// import Typography from '../views/typography/Main.vue'
// import Icon from '../views/icon/Main.vue'
// import LoadingIcon from '../views/loading-icon/Main.vue'
// import RegularForm from '../views/regular-form/Main.vue'
// import Datepicker from '../views/datepicker/Main.vue'
// import TomSelect from '../views/tom-select/Main.vue'
// import FileUpload from '../views/file-upload/Main.vue'
// import WysiwygEditor from '../views/wysiwyg-editor/Main.vue'
// import Validation from '../views/validation/Main.vue'
// import Chart from '../views/chart/Main.vue'
// import Slider from '../views/slider/Main.vue'
// import ImageZoom from '../views/image-zoom/Main.vue'

// Campaign
const Campaign = () => import('../views/campaign/Main.vue')
const CreateCampaign = () => import('../views/campaign/CreateCampaign.vue')
const EditCampaign = () => import('../views/campaign/EditCampaign.vue')
const ShowCampaign = () => import('../views/campaign/ShowCampaign.vue')
const Creator = () => import('../views/creator-database/Main.vue')
const CreatorProfile = () => import('../views/creator-database/CreatorProfile.vue')
const Contact = () => import('../views/contact/Main.vue')
const CreateContact = () => import('../views/contact/CreateContact.vue')
const EditContact = () => import('../views/contact/EditContact.vue')
const Profile = () => import('../views/user/Profile.vue')


const routes = [
  {
    path: '/',
    component: TopMenu,
    children: [
      {
        path: '/',
        name: 'top-menu-dashboard',
        component: DashboardOverview2
      },
      {
        path: '/campaigns',
        name: 'top-menu-campaign',
        component: Campaign
      },
      {
        path: '/campaigns/create',
        name: 'top-menu-campaign-create',
        component: CreateCampaign
      },
      {
        path: '/campaigns/:id',
        name: 'top-menu-campaign-show',
        component: ShowCampaign,
        props: true
      },
      {
        path: '/campaigns/:id/edit',
        name: 'top-menu-campaign-edit',
        component: EditCampaign,
        props: true
      },
      {
        path: '/creators',
        name: 'top-menu-creator',
        component: Creator
      },
      {
        path: '/creators/:id',
        name: 'top-menu-creator-profile',
        component: CreatorProfile
      },
      {
        path: '/contacts',
        name: 'top-menu-contact',
        component: Contact
      },
      {
        path: '/contacts/create',
        name: 'top-menu-contact-create',
        component: CreateContact
      },
      {
        path: '/contacts/:id/edit',
        name: 'top-menu-contact-edit',
        component: EditContact
      },
      {
        path: '/settings/profile',
        name: 'top-menu-setting-profile',
        component: Profile
      },
    ]
  },
  {
    path: '/',
    component: SideMenu,
    children: [
      {
        path: '/',
        name: 'side-menu-dashboard-overview-2',
        component: DashboardOverview2
      },
      {
        path: '/campaigns',
        name: 'side-menu-campaign',
        component: Campaign
      },
      {
        path: '/campaigns/create',
        name: 'side-menu-campaign-create',
        component: CreateCampaign
      },
      {
        path: '/campaigns/:id',
        name: 'side-menu-campaign-show',
        component: ShowCampaign,
        props: true,
      },
      {
        path: '/creators',
        name: 'side-menu-creator',
        component: Creator
      },
      {
        path: '/creators/:id',
        name: 'side-menu-creator-profile',
        component: CreatorProfile
      },
      {
        path: '/contacts',
        name: 'side-menu-contact',
        component: Contact
      },
      {
        path: '/contacts/create',
        name: 'side-menu-contact-create',
        component: CreateContact
      },
      {
        path: '/contacts/:id/edit',
        name: 'side-menu-contact-edit',
        component: EditContact
      },
      {
        path: '/settings/profile',
        name: 'side-menu-setting-profile',
        component: Profile
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  // TODO: Add on after subscription and everything run smoothly
  // {
  //   path: '/register',
  //   name: 'register',
  //   component: Register
  // },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: ForgotPassword
  },
  {
    path: "/register",
    name: "register-verify-email",
    component: EmailVerification,
    props: true
  },
  {
    path: "/register/:id",
    name: "setup-account",
    component: SetupAccount,
    props: true
  },
  {
    path: "/reviews/s/:id",
    name: "weekly-campaign-review",
    component: WeeklyCampaignReview,
    props: true
  },
  {
    path: '/error-page',
    name: 'error-page',
    component: ErrorPage
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

router.beforeEach(async (to, from, next) => {

  // const paramsLocale = to.params.locale ?? process.env.VUE_APP_I18N_LOCALE
  //
  // // load locale messages
  // if (!SUPPORT_LOCALES.includes(paramsLocale)) {
  //   await loadLocaleMessages(i18n, paramsLocale)
  // }
  //
  // // set i18n language
  // setI18nLanguage(i18n, paramsLocale)

  const publicPages = [
    'login',
    'register',
    'forgot-password',
    'register-verify-email',
    'setup-account',
    'error-page',
    'weekly-campaign-review',
    undefined
  ]
  const authRequired = !publicPages.includes(to.name)
  const isLoggedIn = localStorage.getItem('user')

  if (authRequired && !isLoggedIn) {
    next('/login')
  }

  next()
})

export default router
