import instance from "./axios-client";

const API_URL = '/v1/uploads'

class UploadService {
  async uploadFileAsTemp(data) {
    return await instance
      .post(API_URL, data, {
        headers: {'Content-Type': 'multipart/form-data'}
      })
      .then(response => {
        return response.data;
      })
  }
}

export default new UploadService()
