import { ref } from "vue"
import i18n from '@/i18n'

export default () => {
  const { t } = i18n.global
  const passwordScore = ref(0)

  function onPasswordStrengthChecker(event) {
    const password = event.target.value
    const strong = (/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/).test(password);
    const medium = (/^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})/).test(password);

    passwordScore.value = 0

    if(strong) {
      passwordScore.value = 4
    } else if(medium) {
      passwordScore.value = 2
    } else if(password.length > 5) {
      passwordScore.value = 1
    }
  }

  function onUrlFormatter(event) {
    const url = event.target.value

    if((/[a-zA-Z0-9]+/).test(url)) {
      return url.replace(/\s+/g, '-').toLowerCase()
    }
  }

  function onNameValidation(value) {

    if(!value) {
      return t('validation.required')
    }

    if(!(/^[^\s]+(\s+[^\s]+)*$/).test(value)) {
      return t('validation.no_leading_trailing')
    }

    if(value.length < 2) {
      return t('validation.min.string', { attribute: t('auth.wizard.organization_name'), min: 2 })
    }

    return false
  }

  return {
    passwordScore,
    onPasswordStrengthChecker,
    onUrlFormatter,
    onNameValidation
  }
}
