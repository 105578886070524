import instance from "./axios-client";

const API_URL = '/v1/creators';

class CreatorService {
  async fetchCreators($data) {
    return await instance
      .get(API_URL,{ params: $data })
      .then(response => {

        return response.data;
      });
  }

  async fetchCreator(id) {
    return await instance
      .get(API_URL + '/' + id)
      .then(response => {
        return response.data;
      });
  }

  async importCreators(data) {
    return await instance
      .post(API_URL + '/import', data)
      .then(response => {
        return response.data
      })
  }

  async addNewCreator(data) {
    return await instance
      .post(API_URL + '/new', data)
      .then(response => {
        return response.data
      })
  }
}

export default new CreatorService();
