import { ref } from "vue";

export default () => {
  const topLimit = ref(30)
  const dashboardSelectedCampaignId = ref(localStorage.getItem('dashboardSelectedCampaignId') ? localStorage.getItem('dashboardSelectedCampaignId') : null)

  function onSelectTopLimit(limit) {

    localStorage.setItem('topLimit', limit)
    topLimit.value = limit
    cash('.dropdown-menu').dropdown('toggle')
  }

  function onSwitchCampaign(campaignId) {
    localStorage.setItem('dashboardSelectedCampaignId', campaignId)
    dashboardSelectedCampaignId.value = campaignId
    cash('.dropdown-menu').dropdown('toggle')
  }

  return {
    topLimit,
    dashboardSelectedCampaignId,
    onSelectTopLimit,
    onSwitchCampaign
  }
}
