import instance from "./axios-client"

const API_URL = "/v1"

class AuthService {
  login(user) {
    return instance
      .post(API_URL + '/login', {
        email: user.email,
        password: user.password
      })
      .then(response => {

        return response.data
      });
  }

  register(user) {
    return instance.post(API_URL + '/register', {
      email: user.email,
    });
  }

  verify(user) {
    return instance.post(API_URL + '/register/verify-email', {
      email: user.email,
      verification_code: user.verification_code
    });
  }

  forgotPassword(user) {
    return instance.post(API_URL + '/forgot-password', {
      email: user.email,
    })
  }

  complete(user) {
    return instance.post(API_URL + '/register/' + user.id + '/create', {
      id: user.id,
      organization_logo: user.organizationLogo,
      organization_name: user.organizationName,
      organization_url: user.organizationUrl,
      full_name: user.fullName,
      password: user.password,
      idd: user.idd,
      phone: user.phone,
      industry: user.industry,
      business_category: user.businessCategory,
      country: user.country,
      usage: user.usage,
      certificate_files: user.certificateFiles
    });
  }

  checkOrganizationUrl(url) {
    return instance.post(API_URL + '/register/check', {
      organization_url: url,
    });
  }
}

export default new AuthService();
