<template>
  <div>

    <div class="grid grid-cols-12 gap-6 mt-2">
      <div v-for="(campaign, key) in campaigns"
           :key="key"
           class="intro-y col-span-12 md:col-span-6"
      >
        <div class="box zoom-in">
          <div class="flex flex-col lg:flex-row items-center px-5 py-4 border-gray-200 dark:border-dark-5">
            <div class="rounded-2xl flex-shrink-0 flex flex-col items-baseline md:items-center lg:mr-1">
              <div class="mb-3">
                <a :class="'is-' + campaign.class"
                   class="w-28 inline-block rounded-full px-6 p-2 leading-none text-white uppercase text-sm font-bold text-center bg-theme-2">
                  {{ $t(`campaign.${campaign.class}`) }}
                </a>
              </div>
              <img :alt="campaign.name"
                   class="w-24 h-24 lg:w-24 lg:h-12 image-fit lg:mr-1 rounded-md"
                   v-lazy="campaign.banner_url"
              >
            </div>
            <div class="w-full lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0">
              <a href="" class="font-semibold text-base">{{ campaign.name }}</a>
              <div class="flex items-center mt-1">
                <div class="w-2 h-2 rounded-full mr-3"
                     :class="campaign.status_color"
                />
                <span class="truncate">{{ $t(`campaign.status.${campaign.status}`) }}</span>
              </div>
              <div class="flex flex-wrap lg:flex-nowrap items-center justify-center pt-3">
                <div class="w-full mb-4 lg:mb-0 mr-auto">
                  <div class="flex text-gray-600 text-sm">
                    <div class="mr-auto">
                      {{
                        campaign.enrolled_creators_count > 0 ?
                          Math.round(campaign.completed_creators_count / campaign.enrolled_creators_count * 100) :
                          0
                      }} of 100%</div>
                    <div>
                      {{ campaign.end_date ? $t('campaign.remaining_days', {days: campaign.remaining_days}) : $t('campaign.indefinite_date') }}
                    </div>
                  </div>
                  <progress-bar :width="campaign.enrolled_creators_count > 0 ? Math.round(campaign.completed_creators_count / campaign.enrolled_creators_count * 100) : 0" />
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-between items-center px-5 py-3 border-t border-gray-200 dark:border-dark-5">
            <div class="intro-x flex mr-2">
              <div v-for="(creator, key) in (campaign.creators.length > 6 ? campaign.creators.slice(0, 6) : campaign.creators )"
                   :key="key"
                   class="intro-x w-8 h-8 image-fit"
                   :class="{'-ml-4' : key > 0}"
              >
                <Tippy tag="img"
                       :alt="creator.profile.full_name"
                       class="rounded-full border border-white zoom-in"
                       v-lazy="creator.profile.profile_picture_url"
                       :content="creator.profile.full_name ? creator.profile.full_name : creator.profile.username"
                       @click.stop="onCreatorProfile(creator.links.self)"
                />
              </div>
              <div class="intro-x w-8 h-8 flex items-center -ml-4 bg-theme-25 rounded-full border border-white"
                   v-if="campaign.creators.length > 6"
              >
                <Tippy tag="span"
                       class=" w-8 h-8 text-center rounded-full border border-white zoom-in"
                       :content="campaign.creators.length - 1 + '+ ' + $t('campaign.total_creator')"
                >...</Tippy>
              </div>
            </div>
            <div class="flex items-center">
              <Tippy tag="span"
                     class="intro-x flex items-center justify-center text-gray-600 dark:bg-dark-5 dark:text-gray-300 cursor-pointer"
                     :content="$t('campaign.total_reach')"
              >
                <EyeIcon class="w-4 h-4 mr-1" />
                {{ $h.abbreviateNumber(campaign.total_reach) }}
              </Tippy>
              <Tippy tag="span"
                     class="intro-x flex items-center justify-center text-gray-600 dark:bg-dark-5 dark:text-gray-300 ml-4 cursor-pointer"
                     :content="$t('campaign.engagement_rate')"
              >
                <BarChartIcon class="w-4 h-4 mr-1" />
                {{ $h.abbreviateNumber(campaign.average_engagement) }}%
              </Tippy>
              <Tippy tag="span"
                     class="intro-x flex items-center justify-center text-gray-600 dark:bg-dark-5 dark:text-gray-300 ml-4 cursor-pointer"
                     :content="$t('campaign.total_creator')"
              >
                <UserIcon class="w-4 h-4 mr-1" />
                {{ $h.abbreviateNumber(campaign.enrolled_creators_count) }}
              </Tippy>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CampaignSummaryListingSkeleton from "../../../components/skeleton/CampaignSummaryListingSkeleton.vue"
import ProgressBar from "../../../global-components/progress-bar/ProgressBar.vue"
import useCreatorDatabase from "../../../composables/useCreatorDatabase.js"

defineProps({
  campaigns: {
    type: Object,
  },
  isCampaignLoading: {
    type: Boolean,
  },
})

const { onCreatorProfile } = useCreatorDatabase()
</script>
