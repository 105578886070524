import { configure, defineRule, ErrorMessage, Field as VeeField, Form as VeeForm } from 'vee-validate'
import { localize, setLocale } from '@vee-validate/i18n';
import en from '../locales/validation/en.json';
import zhHK from '../locales/validation/zh-hk.json';
import zhCN from '../locales/validation/zh-cn.json';

import {
  alpha_spaces as alphaSpaces,
  confirmed,
  email,
  max,
  max_value as maxVal,
  min,
  min_value as minVal,
  not_one_of as excluded,
  required
} from '@vee-validate/rules'

export default {
  install(app) {
    app.component('VeeForm', VeeForm);
    app.component('VeeField', VeeField);
    app.component('ErrorMessage', ErrorMessage);

    defineRule('required', required);
    defineRule('min', min);
    defineRule('max', max);
    defineRule('alpha_spaces', alphaSpaces);
    defineRule('email', email);
    defineRule('min_value', minVal);
    defineRule('max_value', maxVal);
    defineRule('excluded', excluded);
    defineRule('country_excluded', excluded);
    defineRule('password_mismatch', confirmed);

    configure({
      generateMessage: localize({
        en: en,
        zh_HK: zhHK,
        zh_CN: zhCN
      }),
      validateOnBlur: true,
      validateOnChange: true,
      validateOnInput: false,
      validateOnModelUpdate: true,
    });

    localStorage.setItem('locale', 'zh_HK')
    setLocale(localStorage.getItem("locale" || process.env.VUE_APP_I18N_LOCALE));
  },
};
