<template>
  <div class="flex items-center gap-0.5">
    <span class="text-sm text-primary-1 text-opacity-50 mr-2">{{ current }}/{{ last }}</span>

    <a
      :class="{'disable text-opacity-40 cursor-auto': !hasPrev() }"
      class="prev text-primary-1 bg-theme-25 px-2 py-0.5 rounded-l-full" href="javascript:void(0);"
       @click.prevent="changePage(prevPage)"
    >
      <ChevronLeftIcon class="w-4 h-4"/>
    </a>

    <a
      :class="{'disable text-opacity-40 cursor-auto': !hasNext() }"
      class="next text-primary-1 bg-theme-25 px-2 py-0.5 rounded-r-full" href="javascript:void(0);"
       @click.prevent="changePage(nextPage)"
    >
      <ChevronRightIcon class="w-4 h-4" />
    </a>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Small Pagination',
  props: {
    current: {
      type: Number,
      default: 1
    },
    last: {
      type: Number,
      default: 0
    },
  },
  methods: {
    hasPrev: function () {
      return this.current > 1
    },
    hasNext: function () {
      return this.current < this.last
    },
    changePage: function (page) {
      if (page > 0 && page <= this.last) {
        this.$emit('page-changed', page)
      }
    },
  },
  computed: {
    nextPage: function () {
      return this.current + 1
    },
    prevPage: function () {
      return this.current - 1
    }
  }
})
</script>
