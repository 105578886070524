<template>
  <vue-highcharts
    type="chart"
    :options="options"
    :redrawOnUpdate="true"
    :oneToOneUpdate="false"
    :animateOnUpdate="true"
    @rendered="onRender"
    @update="onUpdate"
    @destroy="onDestroy"/>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import VueHighcharts from 'vue3-highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
HighchartsMore(Highcharts)

const key = ref(0)
const props = defineProps({
  options: {
    type: Object,
    required: true,
    default: () => ({})
  },
})

const onRender = () => {
}

const onUpdate = () => {
}

const onDestroy = () => {
}
</script>
