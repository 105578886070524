<template>
  <div>
    <h3 class="font-bold text-lg text-center mb-5 leading-normal">
      {{ $t('app.creator') }}
      <br>
      {{ $t('dashboard.creator_influence_analysis_title') }}
    </h3>
    <hr class="my-5 opacity-50">
    <div class="flex items-center gap-2 mt-8 mb-5">
      <div class="dropdown relative w-full md:w-60">
        <div class="organization-dropdown-toggle dropdown-toggle btn w-full flex items-center justify-start px-3 py-1.5 border border-theme-7 bg-theme-15 bg-opacity-50 dark:bg-dark-2 dark:border-dark-2"
             role="button"
             aria-expanded="false">
          <div class="flex-col text-left">
            <div class="text-xs mb-1 font-semibold">{{ $t('campaign.name') }}</div>
            <div class="truncate font-normal">{{ campaigns?.find((x) => x.id === dashboardSelectedCampaignId)?.name }}</div>
          </div>
          <chevron-down-icon class="w-5 h-5 ml-auto"/>
        </div>
        <div class="dropdown-menu w-full">
          <div class="dropdown-menu__content box dark:bg-dark-1 p-2 h-auto max-h-60 overflow-y-auto">
            <a v-for="(campaign, key) in campaigns"
               :key="key"
               href="javascript:"
               class="flex items-center block p-2 transition duration-300 ease-in-out dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md mb-0.5"
               :class="(campaign.id === dashboardSelectedCampaignId) ? 'bg-gray-200' : 'bg-white'"
               @click="onSwitchCampaign(campaign.id)"
            >
              <div class="pl-1 truncate">{{ campaign.name }}</div>
            </a>
          </div>
        </div>
      </div>
      <div class="boxed-tabs nav nav-tabs h-11 justify-center border border-gray-400 border-dashed text-gray-600 dark:border-gray-700 dark:bg-dark-3 dark:text-gray-500 rounded-md p-1 ml-auto"
           role="tablist"
      >

        <a id="monthly-tab" aria-selected="true"
           class="btn flex-1 border-0 shadow-none py-1.5 px-2 active" data-target="#weekly-users"
           data-toggle="tab"
           href="javascript:;" role="tab">
          Monthly
        </a>

      </div>
    </div>
    <chart-content-loader :isLoading="isCampaignChartLoading" />
    <div class="p-5 text-center" v-if="!isCampaignChartLoading && !campaignChartData?.chart.draw">
      <img src="@/assets/images/fallback-image.svg" class="w-48 h-48 mx-auto">
      <span class="text-description">{{ $t('app.no_data_found') }}</span>
    </div>
    <column-chart v-if="!isCampaignChartLoading && campaignChartData?.chart.draw" :options="chartOptions" />
  </div>

</template>

<script setup>
import { ref, computed, watch, onMounted } from "vue"
import ColumnChart from "../../components/chart/ColumnChart.vue"
import useDropdown from "../../composables/useDropdown.js"
import useDashboard from "../../composables/useDashboard.js"
import i18n from '@/i18n'
const { t } = i18n.global

const props = defineProps({
  campaigns: {
    type: Object,
  },
})
const {
  dashboardSelectedCampaignId,
  onSwitchCampaign
} = useDropdown()

if(dashboardSelectedCampaignId.value === null) {
  localStorage.setItem('dashboardSelectedCampaignId', props.campaigns[0]?.id)
}

const {
  isCampaignChartLoading,
  campaignChartData,
  onFetchingCampaignAnalysis
} = useDashboard()

if(!localStorage.getItem('chartSelectedCampaignId')) {
  localStorage.setItem('chartSelectedCampaignId', props.campaigns[0].id)
}

onMounted(() => {
  onFetchingCampaignAnalysis(dashboardSelectedCampaignId.value)
})

watch(() => [
  dashboardSelectedCampaignId.value,
], (
  [dashboardSelectedCampaignIdValue],
) => {
  if(dashboardSelectedCampaignIdValue) {
    onFetchingCampaignAnalysis(dashboardSelectedCampaignId.value)
  }
})

const chartOptions = computed(() => {
  return {
    chart: {
      type: 'column',
      height: '85%'
    },
    title: {
      text: null,
    },
    legend: {
      layout: 'horizontal',
      verticalAlign: 'top',
    },
    xAxis: {
      categories: campaignChartData.value?.chart.labels,
      crosshair: true
    },
    yAxis: [{
      min: 0,
      title: {
        text: t('campaign.total_reach')
      }
    },
      {
        title: {
          text: t('campaign.engagement_rate') + ' (%)'
        },
        opposite: true
      }],
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
      footerFormat: '</table>',
      shared: true,
      useHTML: true
    },
    plotOptions: {
      column: {
        grouping: false,
        shadow: false,
        borderWidth: 0,
      }
    },
    series: [
      {
        name: t('campaign.total_reach'),
        color: 'rgba(248,161,63,1)',
        data: campaignChartData.value?.chart.reaches,
        pointPadding: 0.2,
        pointPlacement: -0.2
      }, {
        name: t('campaign.engagement_rate'),
        color: 'rgba(186,60,61,.9)',
        data: campaignChartData.value?.chart.engagement,
        pointPadding: 0.3,
        pointPlacement: -0.2,
        yAxis: 1,
        tooltip: {
          valueSuffix: ' %'
        },
      },
    ],
    credits: {
      enabled: false
    },
  }
})
</script>
