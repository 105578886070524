<template>
  <div class="spring-spinner" :style="spinnerStyle">
    <div class="spring-spinner-part top" :style="spinnerPartStyle">
      <div class="spring-spinner-rotator" :style="rotatorStyle"></div>
    </div>
    <div class="spring-spinner-part bottom" :style="spinnerPartStyle">
      <div class="spring-spinner-rotator" :style="rotatorStyle"></div>
    </div>
  </div>
</template>

<script>
import utils from '../../utils/icon-utils'
export default {
  props: {
    animationDuration: {
      type: Number,
      default: 3000
    },
    size: {
      type: Number,
      default: 70
    },
    color: {
      type: String,
      default: '#fff'
    }
  },
  data () {
    return {
      animationName: `spring-spinner-animation`
    }
  },
  computed: {
    spinnerStyle () {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`
      }
    },
    spinnerPartStyle () {
      return {
        height: `${this.size / 2}px`,
        width: `${this.size}px`
      }
    },
    rotatorStyle () {
      return {
        height: `${this.size}px`,
        width: `${this.size}px`,
        borderRightColor: this.color,
        borderTopColor: this.color,
        borderWidth: `${this.size / 7}px`,
        animationDuration: `${this.animationDuration}ms`,
        animationName: this.animationName
      }
    }
  },
  watch: {
    size: {
      handler: 'updateAnimation',
      immediate: true
    },
    color: {
      handler: 'updateAnimation',
      immediate: true
    }
  },
  mounted () {
    this.updateAnimation()
  },
  beforeDestroy () {
    utils.removeKeyframes(this.animationName)
  },
  methods: {
    updateAnimation () {
      utils.removeKeyframes(this.animationName)
      utils.appendKeyframes(this.animationName, this.generateKeyframes())
    },
    generateKeyframes () {
      return `
          0% {
            border-width: ${this.size / 7}px;
          }
          25% {
            border-width: ${this.size / 23.33}px;
          }
          50% {
            transform: rotate(115deg);
            border-width: ${this.size / 7}px;
          }
          75% {
            border-width: ${this.size / 23.33}px;
           }
          100% {
           border-width: ${this.size / 7}px;
          }`
    }
  }
}
</script>

<style scoped>

</style>
