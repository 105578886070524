import { ref, reactive } from "vue"
import { useRouter } from "vue-router"
import CampaignService from "../services/campaign.service"
import statusesJson from "../assets/json/campaign-statuses.json"
import categoriesJson from "../assets/json/categories.json"

export default () => {
  const router = useRouter()
  const isLoading = ref(false)
  const categories = categoriesJson
  const statuses = statusesJson
  const errorMessage = ref("")
  const campaigns = ref(null)
  const campaign = ref(null)
  const reviews = ref([])
  const meta = reactive({
    page: 1,
    currentPage: 1,
    lastPage: 0,
    total: 0,
    perPage: 10,
  })
  const filters = reactive({
    page: meta.page,
    limit: 10,
    status: "all",
    categories: 0,
    sort: "-created_at",
    search: null
  })

  function onFetchingCampaigns() {
    isLoading.value = true

    CampaignService.fetchCampaigns(
      filters
    ).then(
      (response) => {
        meta.currentPage = response.meta.current_page
        meta.lastPage = response.meta.last_page
        meta.total = response.meta.total
        meta.perPage = response.meta.per_page
        campaigns.value = response.data
        isLoading.value = false
      },
      (error) => {
        errorMessage.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
      }
    )
  }

  function onFetchingCampaign(id) {
    CampaignService.fetchCampaign(id).then(
      (response) => {
        campaign.value = response.data
        isLoading.value = false
      },
      (error) => {
        errorMessage.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
      }
    )
  }

  function onShowCampaign(id) {
    router.push(`/campaigns/${id}`)
  }

  function onPaginate(page) {
    filters.page = page
    onFetchingCampaigns()
  }

  function onPaginateReview(id) {
    onFetchingReviews(id, meta.page)
  }

  function onCategoriesFilter(value) {
    filters.categories = value

    onFetchingCampaigns(1)
  }

  function onStatusFilter(value) {
    filters.status = value

    onFetchingCampaigns(1)
  }

  function onCreateCampaign() {
    router.push(`/campaigns/create`)
  }

  function onEditCampaign(id) {
    router.push(`/campaigns/${id}/edit`)
  }

  function onFetchingReviews(id, page) {
    isLoading.value = true

    CampaignService.fetchCampaignReviews(id, {
      page: page,
      limit: 12
    }).then(
      (response) => {
        meta.currentPage = response.meta.current_page
        meta.lastPage = response.meta.last_page
        meta.total = response.meta.total
        meta.perPage = response.meta.per_page
        reviews.value = response.data
        isLoading.value = false
      },
      (error) => {
        errorMessage.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
      }
    )
  }

  function onCancellingCampaign(id) {
    isLoading.value = true

    CampaignService.cancelCampaign(id).then(
      (response) => {
        onFetchingCampaigns(1)
      },
      (error) => {
        errorMessage.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
      }
    )
  }

  return {
    isLoading,
    categories,
    statuses,
    errorMessage,
    campaigns,
    campaign,
    reviews,
    filters,
    meta,
    onFetchingCampaigns,
    onFetchingCampaign,
    onFetchingReviews,
    onShowCampaign,
    onCategoriesFilter,
    onStatusFilter,
    onCreateCampaign,
    onPaginate,
    onPaginateReview,
    onCancellingCampaign,
    onEditCampaign
  }
}
