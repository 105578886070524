<template>
  <div class="bg-white p-2 w-full border rounded-lg">
    <v-date-picker
      v-model="selected.date"
    >
      <template #default="{ togglePopover, hidePopover }">
        <div class="flex flex-wrap">
          <button
            v-for="(date) in dates"
            :key="date.date.getTime()"
            class="flex items-center bg-blue-100 hover:bg-blue-200 text-blue-600 text-sm font-semibold h-8 px-2 m-1 rounded-lg border-2 border-transparent focus:outline-none"
            @click.stop="dateSelected($event, date, togglePopover)"
            ref="button"
          >
            {{ date.date.toLocaleDateString() }}
            <svg
              class="w-4 h-4 text-gray-600 ml-1 -mr-1"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
              @click.stop="removeDate(date, hidePopover)"
            >
              <path d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        </div>
      </template>
    </v-date-picker>
    <button
      class="text-sm text-blue-600 font-semibold hover:text-blue-500 px-2 h-8 focus:outline-none"
      @click.stop="addDate"
    >
      <PlusIcon class="w-4 h-4" />
      {{ $t("button.add_date") }}
    </button>
  </div>
</template>

<script>
import moment from "moment"

export default {
  props: {
    modelValue: Array,
    isEdit: Boolean,
    isReset: Boolean,
    initDate: Array
  },
  emits: ["update:modelValue"],
  data() {
    return {
      dates: [],
      selected: {},
      formattedDates: []
    };
  },

  watch: {
    initDate: {
      immediate: true,
      handler: function(val) {
        if (this.initDate) {
          this.dates = this.initDate
        }
      }
    },
    isReset: function(bool) {
      if (bool) {
        this.dates = []
      }
    },

    'selected.date': function(val, old) {
      this.handleFormattedDate()
    }
  },
  methods: {
    handleFormattedDate() {
      this.formattedDates = this.dates.map(item => {
        return moment(item.date).format("DD/MM/YYYY")
      })

      this.formattedDates = [...new Set(this.formattedDates)]
      this.$emit("update:modelValue", this.formattedDates)
    },
    addDate() {
      this.dates.push({
        date: new Date()
      })

      this.$nextTick(() => {
        const btn = this.$refs.button
        btn.click()
      })
    },
    removeDate(date, hide) {
      this.dates = this.dates.filter((d) => d !== date)

      this.handleFormattedDate()
      hide()
    },
    dateSelected(e, date, toggle) {
      this.selected = date

      toggle({ ref: e.target })
    },
  }
}
</script>
