<template>
  <div>
    <h3 class="font-bold text-lg text-center mb-5 leading-normal">
      {{ $t('app.creator') }}
      <br>
      {{ $t('dashboard.creator_influence_analysis_title') }}
    </h3>
    <hr class="my-5 opacity-50">
    <div class="flex gap-2 mt-8 mb-5">
      <div class="dropdown relative w-full md:w-60">
        <div class="organization-dropdown-toggle dropdown-toggle btn w-full flex items-center justify-start px-3 py-1.5 border border-theme-7 bg-theme-15 bg-opacity-50 dark:bg-dark-2 dark:border-dark-2"
             role="button"
             aria-expanded="false">
          <div class="flex-col text-left">
            <div class="text-xs mb-1 font-semibold">{{ $t('campaign.name') }}</div>
            <div class="truncate font-normal">{{ campaigns?.find((x) => x.id === dashboardSelectedCampaignId)?.name }}</div>
          </div>
          <chevron-down-icon class="w-5 h-5 ml-auto"/>
        </div>
        <div class="dropdown-menu w-full">
          <div class="dropdown-menu__content box dark:bg-dark-1 p-2 h-auto max-h-60 overflow-y-auto">
            <a v-for="(campaign, key) in campaigns"
               :key="key"
               href="javascript:"
               class="flex items-center block p-2 transition duration-300 ease-in-out dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md mb-0.5"
               :class="(campaign.id === dashboardSelectedCampaignId) ? 'bg-gray-200' : 'bg-white'"
               @click="onSwitchCampaign(campaign.id)"
            >
              <div class="pl-1 truncate">{{ campaign.name }}</div>
            </a>
          </div>
        </div>
      </div>
      <div class="dropdown relative w-full md:w-60">
        <div class="organization-dropdown-toggle dropdown-toggle btn w-full flex items-center justify-start px-3 py-1.5 border border-theme-7 bg-theme-15 bg-opacity-50 dark:bg-dark-2 dark:border-dark-2"
             role="button"
             aria-expanded="false">
          <div class="flex-col text-left">
            <div class="text-xs mb-1 font-semibold">{{ $t('creator.top') }}</div>
            <div class="truncate font-normal">{{ topLimit }}</div>
          </div>
          <chevron-down-icon class="w-5 h-5 ml-auto"/>
        </div>
        <div class="dropdown-menu w-full">
          <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
            <a href="javascript:"
               class="flex items-center block p-2 transition duration-300 ease-in-out dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md mb-0.5"
               :class="topLimit === 10 ? 'bg-gray-200' : 'bg-white'"
               @click="onSelectTopLimit(10)"
            >
              <div class="pl-1 truncate">10</div>
            </a>
            <a href="javascript:"
               class="flex items-center block p-2 transition duration-300 ease-in-out dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md mb-0.5"
               :class="topLimit === 20 ? 'bg-gray-200' : 'bg-white'"
               @click="onSelectTopLimit(20)"
            >
              <div class="pl-1 truncate">20</div>
            </a>
            <a href="javascript:"
               class="flex items-center block p-2 transition duration-300 ease-in-out dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md mb-0.5"
               :class="topLimit === 30 ? 'bg-gray-200' : 'bg-white'"
               @click="onSelectTopLimit(30)"
            >
              <div class="pl-1 truncate">30</div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <chart-content-loader :isLoading="isCreatorInfluenceChartLoading" />
    <packed-bubble-chart v-if="!isCreatorInfluenceChartLoading && creatorInfluenceChartData?.length > 0" :chartData="creatorInfluenceChartData" />
    <div class="p-5 text-center" v-if="!isCreatorInfluenceChartLoading && creatorInfluenceChartData?.length < 1">
      <img src="@/assets/images/fallback-image.svg" class="w-48 h-48 mx-auto">
      <span class="text-description">{{ $t('app.no_data_found') }}</span>
    </div>
  </div>
</template>

<script setup>
import { watch, onMounted } from "vue"
import i18n from "../../i18n"
import useDropdown from "../../composables/useDropdown.js"
import useDashboard from "../../composables/useDashboard.js"
import PackedBubbleChart from "../../components/chart/PackedBubbleChart.vue"

const { t } = i18n.global
const props = defineProps({
  campaigns: {
    type: Object,
  },
})
const {
  topLimit,
  onSelectTopLimit,
  dashboardSelectedCampaignId,
  onSwitchCampaign
} = useDropdown()
const {
  isCreatorInfluenceChartLoading,
  creatorInfluenceChartData,
  onFetchingCreatorInfluenceAnalysis,
} = useDashboard()
if(dashboardSelectedCampaignId.value === null) {
  localStorage.setItem('dashboardSelectedCampaignId', props.campaigns[0]?.id)
}
onMounted(() => {
  onFetchingCreatorInfluenceAnalysis(dashboardSelectedCampaignId.value, topLimit.value)
})

watch(() => [
  topLimit.value,
  dashboardSelectedCampaignId.value,
], (
  [topLimitValue, dashboardSelectedCampaignIdValue],
) => {
  if(dashboardSelectedCampaignIdValue || topLimitValue) {
    onFetchingCreatorInfluenceAnalysis(dashboardSelectedCampaignId.value, topLimit.value)
  }
})
</script>
