import { ref, reactive } from "vue"
import * as yup from "yup"
import wizardStepJson from "../assets/json/setup-account-wizard-step.json"
import i18n from '@/i18n'

export default () => {
  const { t } = i18n.global
  const isLoading = ref(false)
  const onCurrentStep = ref(0)
  const tabs = ref(wizardStepJson)
  const totalTabs = ref(0)
  const form = reactive({
    id: null,
    organizationLogo: null,
    organizationName: '',
    organizationUrl: '',
    showOrganizationUrl: false,
    fullName: '',
    idd: '',
    phone: '',
    countryCode: '',
    country: '',
    businessCategory: '',
    industry: '',
    usage: '',
  })
  const validate = reactive({
    organizationLogo: false,
    organizationUrl: false,
    emptyOrganizationUrl: false,
    phone: false,
    country: false,
    businessCategory: false,
    industry:false,
    usage: false,
    certificateFiles: false,
  })

  const schema = [
    yup.object().shape({
      // organizationName: yup.string()
      //   .matches(/^[^\s]+(\s+[^\s]+)*$/, t('validation.no_leading_trailing'))
      //   .min(2, t('validation.min.string', { attribute: t('auth.wizard.organization_name'), min: 2 }))
      //   .required(t('validation.required')),
    }),
    yup.object().shape({
      fullName: yup.string()
        .matches(/^[^\s]+(\s+[^\s]+)*$/, t('validation.no_leading_trailing'))
        .required(t('validation.custom.required')),
      password: yup.string()
        .min(6, t('validation.min.password', { attribute: t('auth.password'), min: 6 }))
        .required(t('validation.custom.required')),
      phoneNumber: yup.string().nullable()
        .required(t('validation.custom.required')),
    }),
  ]

  function setActiveStep(onCurrentStep) {
    tabs.value.forEach(tab => {
      tab.isActive = false
    })
    tabs.value[onCurrentStep].isActive = true
    tabs.value[onCurrentStep].isDirty = true
  }

  function onStepChange(index) {
    if (this.tabs[index].isCompleted) {
      setActiveStep(index)
      onCurrentStep.value = index

      return
    }

    if((index - 1 >= 0) && this.tabs[index - 1].isCompleted) {
      setActiveStep(index)
      onCurrentStep.value = index
    }
  }

  function onPrevStep() {
    if (onCurrentStep.value <= 0) {
      return
    }
    onCurrentStep.value--
  }

  return {
    schema,
    isLoading,
    tabs,
    totalTabs,
    onCurrentStep,
    form,
    validate,
    setActiveStep,
    onStepChange,
    onPrevStep
  }
}
