<template>
  <vue-highcharts
    :key="key"
    type="chart"
    :options="chartData"
    :redrawOnUpdate="true"
    :oneToOneUpdate="false"
    :animateOnUpdate="true"
    @rendered="onRender"
    @update="onUpdate"
    @destroy="onDestroy"/>
</template>

<script setup>
import VueHighcharts from 'vue3-highcharts'
import HighchartsMore from 'highcharts/highcharts-more'
import Highcharts from 'highcharts'
import { ref, computed, watch, reactive } from 'vue'
import i18n from '../../i18n'
HighchartsMore(Highcharts)

const { t } = i18n.global
const props = defineProps({
  chartData: {
    type: Object
  },
})
const key = ref(0)
const result = props.chartData
const chartData = reactive({
  chart: {
    type: 'packedbubble',
    height: '85%'
  },
  legend: {
    layout: 'horizontal',
    verticalAlign: 'top',
  },
  title: {
    text: null,
  },
  tooltip: {
    useHTML: true,
    pointFormat: `<b>{point.name}(@{point.username})</b><br>${t('creator.followers')}: {point.follower}<br>${t('creator.engagement_rate')}: {point.engagement_rate}%`
  },
  plotOptions: {
    packedbubble: {
      minSize: '20%',
      maxSize: '100%',
      zMin: 0,
      zMax: 1000,
      layoutAlgorithm: {
        gravitationalConstant: 0.05,
        splitSeries: true,
        seriesInteraction: false,
        dragBetweenSeries: false,
        parentNodeLimit: true,
      },
      dataLabels: {
        enabled: false,
        format: '{point.name}',
        filter: {
          property: 'y',
          operator: '>',
          value: 250
        },
        style: {
          color: 'black',
          textOutline: 'none',
          fontWeight: 'normal'
        }
      }
    },
  },
  series: result,
  credits: {
    enabled: false
  },
})

watch(props, () => {
  chartData.series = props.chartData
})

const onRender = () => {
}

const onUpdate = () => {

}

const onDestroy = () => {
}
</script>
