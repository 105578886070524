<template>
  <div
      class="relative bg-white rounded-lg border cursor-pointer focus:outline-none hover:border-gray-500 col-span-12"
      :class="[{'border-theme-10 bg-green-50 ' : modelValue === selectionName}, 'md:col-span-' + (colSpan ?? 6) ]"
      @click="onUpdateSelection"
      @keydown.space="onUpdateSelection"
  >
      <label class="flex flex-col justify-center items-center text-center rounded-lg p-6 cursor-pointer focus:outline-none">
          <div class="absolute top-1 right-1.5" v-if="modelValue === selectionName">
              <CheckCircleIcon class="w-4 h-4 text-theme-10"></CheckCircleIcon>
          </div>
          <slot></slot>
          <vee-field :name="name" type="radio" :value="modelValue">
              {{ description }}
          </vee-field>

      </label>
  </div>
</template>

<script>
export default {
  props: {
    selectionName: String,
    description: String,
    colSpan: Number,
    modelValue: String,
    name: String
  },
  emits: ['update:modelValue'],
  methods: {
      onUpdateSelection() {
          this.$emit('update:modelValue', this.selectionName)
      }
  }
}
</script>
