import { createStore } from 'vuex'
import main from './main'
import sideMenu from './side-menu'
import topMenu from './top-menu'
import { auth } from "./modules/auth"

const store = createStore({
  modules: {
    main,
    sideMenu,
    topMenu,
    auth
  }
})

export function useStore () {
  return store
}

export default store
