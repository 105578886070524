<template>
  <div>
    <!-- BEGIN: Application Logo -->
    <a href="/" class="absolute top-0 flex w-36 items-center p-8">
      <img
        alt="{{ $t('app.title') }}"
        class="w-12 h-12"
        src='@/assets/images/logo.jpeg'
      />
      <span class="text-white text-lg font-semibold ml-3">

        {{ $t('app.title') }}
      </span>
    </a>
    <!-- END: Application Logo -->
    <div class="container">
      <!-- BEGIN: Error Page -->
      <div
        class="error-page flex flex-col lg:flex-row items-center justify-center h-screen mt-16 text-center lg:text-left"
      >
        <div class="-intro-x lg:mr-20">
          <img
            alt="error-404"
            class="h-48 lg:h-auto"
            src="@/assets/images/error-illustration.svg"
          />
        </div>
        <div class="text-white mt-10 lg:mt-0">
          <div class="intro-x text-8xl font-medium text-theme-36">404</div>
          <div class="intro-x text-xl lg:text-3xl font-medium mt-5">
            Oops. This page has gone missing.
          </div>
          <div class="intro-x text-lg mt-3">
            You may have mistyped the address or the page may have moved.
          </div>
          <a
            href='/'
            class="intro-x btn py-3 px-4 text-white border-theme-36 dark:border-dark-5 dark:text-gray-300 mt-10 hover:bg-theme-36 hover:text-primary-1"
          >
            {{ $t('back_to_home') }}

            <svg class="hover-arrow" width="10" height="10" viewBox="0 0 10 10" aria-hidden="true">
              <g fill-rule="evenodd">
                <path class="hover-arrow__linepath" d="M0 5h7"></path>
                <path class="hover-arrow__tippath" d="M1 1l4 4-4 4"></path>
              </g>
            </svg>
          </a>
        </div>
      </div>
      <!-- END: Error Page -->
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  setup () {
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('login')
        .addClass('error-page')
    })
  }
})
</script>
