import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import moment from "moment"
import camelcaseKeysDeep from "camelcase-keys-deep"
import campaignTiers from "../assets/json/campaign-tiers.json"
import i18n from '@/i18n'

const { t } = i18n.global
dayjs.extend(duration)

const helpers = {
  cutText (text, length) {
    if (text.split(' ').length > 1) {
      const string = text.substring(0, length)
      const splitText = string.split(' ')
      splitText.pop()
      return splitText.join(' ') + '...'
    } else {
      return text
    }
  },
  formatDate (date, format) {
    return dayjs(date).format(format)
  },
  capitalizeFirstLetter (string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ''
    }
  },
  onlyNumber (string) {
    if (string) {
      return string.replace(/\D/g, '')
    } else {
      return ''
    }
  },
  formatCurrency (number) {
    if (number) {
      const formattedNumber = number.toString().replace(/\D/g, '')
      const rest = formattedNumber.length % 3
      let currency = formattedNumber.substr(0, rest)
      const thousand = formattedNumber.substr(rest).match(/\d{3}/g)
      let separator

      if (thousand) {
        separator = rest ? '.' : ''
        currency += separator + thousand.join('.')
      }

      return currency
    } else {
      return ''
    }
  },
  timeAgo (time) {
    const date = new Date(
      (time || '').replace(/-/g, '/').replace(/[TZ]/g, ' ')
    )
    const diff = (new Date().getTime() - date.getTime()) / 1000
    const dayDiff = Math.floor(diff / 86400)

    if (isNaN(dayDiff) || dayDiff < 0 || dayDiff >= 31) {
      return dayjs(time).format('MMMM DD, YYYY')
    }

    return (
      (dayDiff === 0 &&
        ((diff < 60 && 'just now') ||
          (diff < 120 && '1 minute ago') ||
          (diff < 3600 && Math.floor(diff / 60) + ' minutes ago') ||
          (diff < 7200 && '1 hour ago') ||
          (diff < 86400 && Math.floor(diff / 3600) + ' hours ago'))) ||
      (dayDiff === 1 && 'Yesterday') ||
      (dayDiff < 7 && dayDiff + ' days ago') ||
      (dayDiff < 31 && Math.ceil(dayDiff / 7) + ' weeks ago')
    )
  },
  diffTimeByNow (time) {
    const startDate = dayjs(
      dayjs()
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
    )
    const endDate = dayjs(
      dayjs(time)
        .format('YYYY-MM-DD HH:mm:ss')
        .toString()
    )

    const duration = dayjs.duration(endDate.diff(startDate))
    const milliseconds = Math.floor(duration.asMilliseconds())

    const days = Math.round(milliseconds / 86400000)
    const hours = Math.round((milliseconds % 86400000) / 3600000)
    let minutes = Math.round(((milliseconds % 86400000) % 3600000) / 60000)
    const seconds = Math.round(
      (((milliseconds % 86400000) % 3600000) % 60000) / 1000
    )

    if (seconds < 30 && seconds >= 0) {
      minutes += 1
    }

    return {
      days: days.toString().length < 2 ? '0' + days : days,
      hours: hours.toString().length < 2 ? '0' + hours : hours,
      minutes: minutes.toString().length < 2 ? '0' + minutes : minutes,
      seconds: seconds.toString().length < 2 ? '0' + seconds : seconds
    }
  },
  isset (obj) {
    if (obj !== null && obj !== undefined) {
      if (typeof obj === 'object' || Array.isArray(obj)) {
        return Object.keys(obj).length
      } else {
        return obj.toString().length
      }
    }

    return false
  },
  toRaw (obj) {
    return JSON.parse(JSON.stringify(obj))
  },
  randomNumbers (from, to, length) {
    const numbers = [0]
    for (let i = 1; i < length; i++) {
      numbers.push(Math.ceil(Math.random() * (from - to) + to))
    }

    return numbers
  },
  getRandomNumberBetween(min,max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  },
  getCategoryIconPath(path) {
    return process.env.VUE_APP_MAIN_URL + 'images/icons/category/' + path
  },
  abbreviateNumber(number) {
    const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

    const tier = Math.log10(Math.abs(number)) / 3 | 0;

    if(tier === 0) { return number; }

    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);

    const scaled = number / scale;

    return parseFloat(scaled.toFixed(1)) + suffix;
  },
  stringToDateFormat(array) {
    if (array) {
      return array.map((selectedDate) => {
        return { date: moment(selectedDate.split('/').join('-'), 'DD-MM-YYYY').toDate() }
      })
    }

    return null
  },
  stringToTimeFormat(obj) {
    const hourObj = {}
    const data = camelcaseKeysDeep(obj)

    console.log(data)
    const formatted = Object.keys(data).map((day) => {
      if (data[day].periods.length === 0) {
        data[day].periods.push(
          {
            open: "08:00",
            close: "17:00"
          }
        )
      }

      hourObj[day] = {
        isOpen: data[day].isOpen,
        periods: data[day].periods.map((period) => {
          const openHour = period.open.split(':')
          const closeHour = period.close.split(':')

          return {
            open: new Date().setHours(openHour[0], openHour[1]),
            close: new Date().setHours(closeHour[0], closeHour[1])
          }
        })
      }

      return hourObj[day]
    })

    return hourObj
  },
  campaignTierName(minFollower, maxFollower, isCustomised) {
    const tier = campaignTiers.filter(tier => tier.min === minFollower && !isCustomised)[0];

    if(tier === undefined) {
      return t('campaign.tier',
        { min: `${new Intl.NumberFormat().format(minFollower)}`,
          max: maxFollower ? `${new Intl.NumberFormat().format(maxFollower) + ' ' + t('campaign.below_max_follower')}` : t('campaign.undefined_max_follower')
        }
      )
    }

    return t(tier.description, {min: `${new Intl.NumberFormat().format(tier.min)}`, max: tier.max ? `${new Intl.NumberFormat().format(tier.max)}` : t('campaign.undefined_max_follower')})
  },
  // TODO: change locales dynamically
  money(money) {
    return new Intl.NumberFormat('zh-HK', {
      style: 'currency',
      currency: 'HKD',
    }).format(money)
  },

  creator_tier_label_color(tier) {
    switch (tier) {
      case 'nano':
        return "#90ed7d"
      case 'micro':
        return '#f7a35c'
      case 'mid_tier':
        return '#7cb5ec'
      case 'macro':
        return '#bc64ee'
      case 'mega':
        return '#ee7764'
      default:
        return '#827e7d'
    }
  },
  creator_tier_level(tier) {
    switch (tier) {
      case 'nano':
        return 2
      case 'micro':
        return 3
      case 'mid_tier':
        return 4
      case 'macro':
        return 5
      case 'mega':
        return 6
      default:
        return 1
    }
  }
}

const install = app => {
  app.config.globalProperties.$h = helpers
}

export { install as default, helpers as helper }
