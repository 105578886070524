import { useRouter } from "vue-router"

export default () => {
  const router = useRouter()

  function onSwitchOrganization(id) {
    router.go()
    localStorage.setItem('tenant', id)
  }

  return {
    onSwitchOrganization
  }
}
