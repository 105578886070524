import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faExchangeAlt,
  faPhone,
  faQuestionCircle,
  faQuestion
} from "@fortawesome/free-solid-svg-icons"

library.add(
  faExchangeAlt,
  faPhone,
  faQuestionCircle,
  faQuestion
)

export default FontAwesomeIcon
