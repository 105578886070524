import axios from "axios"
import store from "@/store"

const baseURL = process.env.VUE_APP_API_URL

const instance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
})

instance.interceptors.request.use(
  (config) => {
    const token = getLocalAccessToken()

    if (token) {
      config.headers.authorization = `Bearer ${token}`
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
)

instance.interceptors.response.use(
  (response) => {
    return response
  },
  async (err) => {
    const originalConfig = err.config

    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true

        try {
          await store.dispatch('auth/logout')
          await this.$router.push('login')

        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data)
          }

          return Promise.reject(_error)
        }
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data)
      }
    }

    return Promise.reject(err)
  }
)

function getLocalAccessToken() {

  const user = JSON.parse(localStorage.getItem('user'))

  if (user && user.access_token.token) {
    return user.access_token.token
  }

  return null
}

export default instance
