<template>
    <div
        class="relative flex h-16 col-span-6 bg-white rounded-lg border cursor-pointer focus:outline-none hover:border-gray-500"
        :class="[{'border-theme-10 bg-green-50 hover:border-theme-10' : this.modelValue === this.slug}, 'md:col-span-' + (colSpan ?? 4) ]"
        @click="onUpdateSelection"
        @keydown.space="onUpdateSelection"
    >
        <div class="flex items-center rounded-lg py-2 px-4 cursor-pointer focus:outline-none">
            <span class="absolute top-0 right-1.5" v-if="this.modelValue === this.slug">
                <CheckCircleIcon class="w-3 h-3 text-theme-10"></CheckCircleIcon>
            </span>
            <slot></slot>
            <span :class='fontClass ?? "text-xs"' :name="name" type="radio" :value="modelValue">
                {{ this.selectionName }}
            </span>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    selectionName: String,
    slug: [String, Boolean],
    modelValue: [String, Boolean],
    name: String,
    fontClass: String,
    selected: Boolean,
    colSpan: Number,
  },
  emits: ['update:modelValue'],
  mounted() {
    if(this.selected) {
      this.onUpdateSelection()
    }
  },
  methods: {
      onUpdateSelection() {
          this.$emit('update:modelValue', this.slug)
      }
  }
}
</script>
