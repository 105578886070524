const isoCountries = require('i18n-iso-countries')
isoCountries.registerLocale(require('i18n-iso-countries/langs/en.json'))
isoCountries.registerLocale(require('i18n-iso-countries/langs/zh.json'))

let locale = localStorage.getItem('locale') || process.env.VUE_APP_I18N_LOCALE

if(locale.includes("zh")) {
    locale = 'zh'
}

const isoCountryList = isoCountries.getNames(locale, { select: 'official' })

const isoCountry = {
    list() {
        return Object.keys(isoCountryList).map((key) => ({ id: key, value: key, label: isoCountryList[key] }));
    }
}

const install = app => {
    app.config.globalProperties.$country = isoCountry;
};

export { install as default, isoCountry as country };
