import VueTelInput from 'vue3-tel-input'
import i18n from '@/i18n'
const { t } = i18n.global

export default {
  install(app) {
    const VueTelInputOptions = {
      mode: "national",
      type: 'tel',
      onlyCountries: ['HK'],
      defaultCountry: "HK",
      dynamicPlaceholder: true,
      disabledFetchingCountry: false,
      disabled: false,
      disabledFormatting: false,
      placeholder: t("placeholder.phone"),
      required: true,
      enabledCountryCode: true,
      enabledFlags: true,
      preferredCountries: [],
      ignoredCountries: [],
      name: "phone",
      inputId:"phone",
      validCharactersOnly: true,
      dropdownOptions: {
        disabledDialCode: false,
        showDialCodeInSelection: true
      },
      inputOptions: {
        showDialCode: false,
        type: 'tel',
        autocomplete: "off",
        required: true,
        validCharactersOnly: true
      }
    }
    app.use(VueTelInput, VueTelInputOptions);
  }
}
