<template>
  <!-- BEGIN: Register Page -->
  <div id="register-page" class="-intro-x">
    <div class="w-full relative z-10 m-auto">
      <div class="items-center">
        <div class="flex max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 md:max-w-1/2 lg:max-w-4xl">
          <!-- BEGIN: Animated Application Logo (Desktop) -->
          <div class="hidden relative bg-theme-36 overflow-hidden z-10 lg:block lg:w-1/2">
            <div class="logo-frame"></div>
          </div>
          <!-- END: Animated Application Logo (Desktop) -->
          <div id="container" class="relative w-full h-screen px-6 py-20 md:h-auto md:py-36 lg:px-8 lg:w-1/2">
            <!-- Application Logo (Mobile) -->
            <img
              :alt="$t('app.title')"
              class="w-24 m-auto pb-2"
              src="@/assets/images/logo.jpeg"
            />
            <!-- Title -->
            <h2 class="text-2xl font-semibold text-center text-title dark:text-white">
              {{ $t('auth.find_my_password') }}
            </h2>

            <!-- BEGIN: Register Form -->
            <vee-form class='px-0 mt-6 lg:px-6 py-6'
                      @submit="handleForgotPassword"
            >

              <!-- Display Message -->
              <div class="-intro-y alert show flex w-full self-center items-center mb-4"
                   role="alert"
                   :class='isSuccessful ? "alert-success bg-teal-50 text-teal-700" : "alert-danger bg-red-50 text-red-700"'
                   v-if="message">
                <AlertOctagonIcon class="w-6 h-6 mr-4" v-if='!isSuccessful'/>
                <CheckCircleIcon class="w-6 h-6 mr-4" v-if='isSuccessful'/>
                <div>
                  {{ message }}
                </div>
              </div>

              <!-- Email -->
              <div>
                <input-label>
                  {{ $t('auth.email') }}
                </input-label>
                <vee-field class="custom-input-field"
                           name="email"
                           id="email"
                           type="text"
                           autocomplete='email'
                           v-model='forms.email'
                />
              </div>

              <!-- Register with Tagmonkey -->
              <button-primary class="mt-6"
                              :disabled="isLoading || !forms.email"
                              :isLoading="isLoading"
              >
                {{ $t('button.submit') }}
              </button-primary>
            </vee-form>
            <!-- END: Register Form -->

            <!-- Link to Login Page -->
            <p class="mt-12 text-sm text-center text-primary-1 text-opacity-60">
              <router-link class="mx-3 font-medium text-primary-1 dark:text-gray-200 underline"
                           :to="{ name: 'login' }"
                           v-html='$t("button.login")'
              />
              |
              <router-link class="mx-3 font-medium text-primary-1 dark:text-gray-200 underline"
                           :to="{ path: 'register' }"
                           v-html='$t("button.register")'
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Register Page -->
</template>

<script setup>
import { ref, reactive, computed, onMounted } from "vue"
import { useStore } from "@/store"
import { useRouter } from "vue-router"
import AuthService from "../../services/auth.service"

const store = useStore()
const router = useRouter()
const isLoading = ref(false)
const isSuccessful = ref(false)
const message = ref("")
const forms = reactive({
  email: "",
})

function handleForgotPassword(user) {
  message.value = ""
  isSuccessful.value = false
  isLoading.value = true

  AuthService.forgotPassword(user).then(
    (data) => {
      message.value = data.message
      isSuccessful.value = true
      isLoading.value = false
    },
    (error) => {
      isSuccessful.value = false
      isLoading.value = false
      message.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
    }
  )
}

const isLoggedIn = computed(() => {
  return store.state.auth.status.isLoggedIn
})

onMounted(() => {
  if (isLoggedIn.value) {
    router.push("/")
  }
})
</script>
