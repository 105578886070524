<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 361.58 368.59">
    <g id="Layer_2" data-name="Layer 2" fill="#ffd200">
      <g id="Layer_2-2" data-name="Layer 2">
        <circle class="cls-1" cx="213.58" cy="94.98" r="94.98"/>
        <circle class="cls-1" cx="107.08" cy="107.14" r="41.5"/>
        <circle class="cls-1" cx="320.08" cy="107.14" r="41.5"/>
        <ellipse class="cls-1" cx="213.58" cy="232.65" rx="68.88" ry="87.23"/>
        <polygon class="cls-1"
                 points="0 214.21 59.58 167.07 119.73 243.09 104.22 284.7 60.15 290.23 0 214.21"/>
        <path class="cls-1"
              d="M158.43,368.59c-16.86,0-33.47-10-41-24.89-1.68-3.33-6.15-12.19-3.69-22.65a28.86,28.86,0,0,1,2.56-6.82,85.19,85.19,0,0,1-7.85-13,120.31,120.31,0,0,1-9.17-25l4.85-1.2A115.52,115.52,0,0,0,112.93,299a81.63,81.63,0,0,0,6.28,10.66,50.48,50.48,0,0,1,4.15-4.78,25.84,25.84,0,0,1,9.9-7.09,18.09,18.09,0,0,1,11.49-1.09,16.93,16.93,0,0,1,9.41,6.48,17.32,17.32,0,0,1,2.38,14,17,17,0,0,1-7,10.48c-4.17,2.78-9.22,3.4-15,1.83-4.32-1.17-7.35-3.48-10.58-6.6-1.49-1.44-2.86-2.9-4.12-4.35a22.24,22.24,0,0,0-1.18,3.62c-2,8.69,1.69,16.1,3.29,19.26,7,13.86,22.84,22.93,38.55,22.08A37.38,37.38,0,0,0,182,355.59c18.31-14.55,14.1-41.28,14.06-41.54l4.93-.82c.2,1.21,4.71,29.91-15.88,46.27a42.45,42.45,0,0,1-24.37,9C160,368.57,159.2,368.59,158.43,368.59Zm-36.05-54.72a64.07,64.07,0,0,0,5,5.45c2.75,2.66,5.11,4.48,8.4,5.38,4.33,1.17,8,.78,10.93-1.17a12,12,0,0,0,4.94-7.48,12.34,12.34,0,0,0-1.61-10,11.8,11.8,0,0,0-6.56-4.51,13.27,13.27,0,0,0-8.46.92,21.24,21.24,0,0,0-8.07,5.88A42.56,42.56,0,0,0,122.38,313.87Z"/>
      </g>
    </g>
  </svg>
</template>
