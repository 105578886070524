import Chart from './chart/Main.vue'
import Highlight from './highlight/Main.vue'
import Tippy from './tippy/Main.vue'
import TippyContent from './tippy-content/Main.vue'
import TomSelect from './tom-select/Main.vue'
import SpringSpinner from './icon-loader/SpringSpinner.vue'
import BreedingRhombusSpinner from './icon-loader/BreedingRhombusSpinner.vue'
import ButtonPrimary from './button/Button.vue'
import InputLabel from './label/InputLabel.vue'
import ApplicationLogo from './app/Logo.vue'
import ApplicationTitle from './app/Title.vue'
import Dropzone from './dropzone/Main.vue'
import * as featherIcons from '@zhuowenli/vue-feather-icons'
import VCalendar from 'v-calendar'
import ToggleButton from './toggle-button/Main.vue'
import DateButton from './date-button/Main.vue'
import ListContentLoader from './content-loader/ListContentLoader.vue'
import ChartContentLoader from './content-loader/ChartContentLoader.vue'
import ToastNotification from './notification/ToastNotification.vue'

export default app => {
  app.component('ApplicationLogo', ApplicationLogo)
  app.component('ApplicationTitle', ApplicationTitle)
  app.component('Chart', Chart)
  app.component('Highlight', Highlight)
  app.component('Tippy', Tippy)
  app.component('TippyContent', TippyContent)
  app.component('TomSelect', TomSelect)
  app.component('Dropzone', Dropzone)
  app.component('InputLabel', InputLabel)
  app.component('ButtonPrimary', ButtonPrimary)
  app.component('SpringSpinner', SpringSpinner)
  app.component('BreedingRhombusSpinner', BreedingRhombusSpinner)
  app.component("ToggleButton", ToggleButton)
  app.component("DateButton", DateButton)
  app.component("ListContentLoader", ListContentLoader)
  app.component("ChartContentLoader", ChartContentLoader)
  app.component("ToastNotification", ToastNotification)
  app.use(VCalendar, {})

  for (const [key, icon] of Object.entries(featherIcons)) {
    icon.props.size.default = '24'
    app.component(key, icon)
  }
}
