<template>
  <!-- BEGIN: Login Page -->
  <div id="login-page" class="-intro-x">
    <toast-notification :success="response.success"
                        :message="response.message"
    />
    <div class="w-full relative z-10 m-auto">
      <div class="items-center">
        <div class="flex max-w-sm mx-auto overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 md:max-w-1/2 lg:max-w-5xl">
          <!-- BEGIN: Animated Application Logo (Desktop) -->
          <div class="hidden relative bg-theme-36 overflow-hidden z-10 lg:block lg:w-1/2">
            <div class="logo-frame"></div>
          </div>
          <!-- END: Animated Application Logo (Desktop) -->
          <div id="container" class="relative w-full h-screen px-6 py-20 md:h-auto md:py-36 lg:px-12 lg:w-1/2">
            <!-- Application Logo (Mobile) -->
            <img
              :alt="$t('app.title')"
              class="w-24 m-auto pb-2"
              src="@/assets/images/logo.jpeg"
            />
            <h2 class="text-2xl font-semibold text-center text-title dark:text-white">
              Crazy Net Army Limited
            </h2>
            <h2 class="text-2xl font-semibold text-center text-title dark:text-white">
              {{ $t('auth.login_as_brand') }}
            </h2>
            <!-- BEGIN: Login Form -->
            <vee-form class='px-0 mt-6 lg:px-6 py-6'
                      @submit="handleLogin"
            >
              <!-- Email -->
              <div class='mb-4'>
                <input-label>
                  {{ $t('auth.email') }}
                </input-label>
                <vee-field class="custom-input-field"
                           name="email"
                           id="email"
                           type="text"
                           autocomplete='email'
                           v-model='forms.email'
                />
              </div>
              <!-- Password -->
              <div>
                <input-label>
                  {{ $t('auth.password') }}
                </input-label>
                <div class='relative'>
                  <vee-field class="custom-input-field"
                         name="password"
                         id="password"
                         :type="passwordFieldType"
                         autocomplete='current-password'
                         v-model='forms.password'
                         ref='password'
                  />
                  <password-toggle-eye-icon :passwordFieldType="passwordFieldType"
                                            @click="(onTogglePasswordVisibility)"
                  />
                </div>
              </div>
              <!-- Login to Tagmonkey -->
              <button-primary class="mt-6"
                              :disabled="isLoading || !forms.email || !forms.password"
                              :isLoading="isLoading"
              >
                {{ $t('button.login') }}
              </button-primary>
            </vee-form>
            <!-- END: Login Form -->

            <!-- TODO: Link to Registration Page -->
<!--            <p class="mt-12 text-sm text-center text-primary-1 text-opacity-60">-->
<!--              {{ $t('auth.no_account') }}-->
<!--              <router-link class="font-medium text-primary-1 dark:text-gray-200 underline cursor-pointer"-->
<!--                           :to="{ path: 'register' }"-->
<!--                           v-html='$t("button.register")'-->
<!--              />-->
<!--            </p>-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Login Page -->
</template>

<script setup>
import useLogin from "../../composables/useLogin"
import useTogglePasswordVisibility from "../../composables/useTogglePasswordVisibility"
import PasswordToggleEyeIcon from "../../components/icon/PasswordToggleEyeIcon.vue"

const { password, passwordFieldType, onTogglePasswordVisibility } = useTogglePasswordVisibility()
const {
  forms,
  isLoading,
  response,
  handleLogin
} = useLogin()
</script>
