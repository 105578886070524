<template>
  <div>
  <!-- BEGIN: Application Logo -->
    <div class="flex items-center justify-between pt-4 pb-6 text-white">
      <div class="flex items-center">
        <application-logo :alt="$t('app.title')" class="w-12 h-12 mr-3" />
        <span class="hidden text-white text-2xl md:block">
          Tag<span class="font-medium">Monkey</span>
        </span>
      </div>
      <div>
        <router-link class="font-medium text-white cursor-pointer p-4 bg-theme-38 rounded-md"
                     :to="{ path: '/login' }"
        >
          <UserIcon class="border rounded-full p-0.5 mr-2" />
          {{ $t("button.go_to_login") }}
        </router-link>
      </div>
    </div>

    <div class="p-2 md:p-5 bg-theme-15 rounded-xl">
      <div class="mb-4">
        <div class="intro-y flex items-center h-10">
          <h2 class="text-lg font-semibold truncate mr-5">{{ $t('dashboard.analytics_overview') }}</h2>
        </div>
        <div class="grid grid-cols-12 gap-6 mt-2 w-full md:w-auto">

          <!-- Total Campaign Participants -->
          <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <UsersIcon class="report-box__icon text-theme-22" />
                    <div class="ml-auto">
                      <Tippy tag="div"
                             class="report-box__indicator bg-theme-10 cursor-pointer"
                             content="22% Higher than last month"
                      >
                        {{ participantPercentage }}%
                        <ChevronUpIcon class="w-4 h-4" v-if="participantPercentage > 0" />
                        <ChevronDownIcon class="w-4 h-4" v-if="participantPercentage < 0" />
                      </Tippy>
                    </div>
                </div>
                <div class="text-3xl font-bold leading-8 mt-6">
                  {{ overview.present?.total_campaign_participant }}
                </div>
                <div class="text-base text-gray-600 mt-1">
                  {{ $t('dashboard.total_campaign_participant') }}
                </div>
              </div>
            </div>
          </div>
          <!-- Average of Follower -->
          <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <UsersIcon class="report-box__icon text-theme-23" />
                </div>
                <div class="text-3xl font-bold leading-8 mt-6">
                  {{ overview.present?.total_campaign_participant }}
                  <span class="text-sm text-gray-500">
                      </span>
                </div>
                <div class="text-base text-gray-600 mt-1">
                  {{ $t('dashboard.average_of_follower') }}
                </div>
              </div>
            </div>
          </div>
          <!-- Total Reach -->
          <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <BarChart2Icon class="report-box__icon text-theme-10" />
                  <!--                        <div class="ml-auto">-->
                  <!--                          <Tippy-->
                  <!--                            tag="div"-->
                  <!--                            class="report-box__indicator bg-theme-10 cursor-pointer"-->
                  <!--                            content="22% Higher than last month"-->
                  <!--                          >-->
                  <!--                            22% <ChevronUpIcon class="w-4 h-4" />-->
                  <!--                          </Tippy>-->
                  <!--                        </div>-->
                </div>
                <div class="text-3xl font-bold leading-8 mt-6">
                  {{ overview.present?.total_campaign_participant }}
                </div>
                <div class="text-base text-gray-600 mt-1">
                  {{ $t('dashboard.total_reach') }}
                </div>
              </div>
            </div>
          </div>
          <!-- Average Engagement -->
          <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">
            <div class="report-box zoom-in">
              <div class="box p-5">
                <div class="flex">
                  <BarChart2Icon class="report-box__icon text-theme-10" />
                  <!--                        <div class="ml-auto">-->
                  <!--                          <Tippy-->
                  <!--                            tag="div"-->
                  <!--                            class="report-box__indicator bg-theme-10 cursor-pointer"-->
                  <!--                            content="22% Higher than last month"-->
                  <!--                          >-->
                  <!--                            22% <ChevronUpIcon class="w-4 h-4" />-->
                  <!--                          </Tippy>-->
                  <!--                        </div>-->
                </div>
                <div class="text-3xl font-bold leading-8 mt-6">
                  {{ overview.present?.total_engagement }}%
                </div>
                <div class="text-base text-gray-600 mt-1">
                  {{ $t('dashboard.average_engagement') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="relative py-5">
        <list-content-loader :isLoading="isLoading" />
        <div class="flex items-center justify-between my-3 gap-0.5">
          <span class="text-xl font-bold">
            {{ meta.total + " " + $tc('creator.result_found', meta.total) }}
          </span>
          <!-- Small Pagination -->
          <small-pagination v-if="meta.lastPage > 1"
                            :current=meta.currentPage
                            :last=meta.lastPage
                            @page-changed="onPaginateReview(props.id, meta.page = current = $event)"
          />
        </div>
        <div class="grid grid-cols-1 md:grid-cols-4 lg:grid-cols-5 mt-5 gap-2">
          <div v-for="(review, key) in reviews"
               :key="key"
               class="profile-card w-full mx-auto overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 cursor-pointer"
               @click.prevent="onOpenMediaPost(review.media.links.self, review.profile.full_name ? review.profile.full_name : review.profile.username)"
          >
            <div class="flex items-center border-b border-gray-200 dark:border-dark-5 px-2 py-3">
              <div class="w-10 h-10 flex-none image-fit">
                <img :alt="review.profile.full_name ? review.profile.full_name : review.profile.username" :src="review.profile.profile_picture_url" class="rounded-full"></div>
              <div class="w-full ml-3 mr-auto">
                <a href="#" class="font-medium block truncate">{{ review.profile.full_name ? review.profile.full_name : review.profile.username }}</a>
                <div class="flex text-gray-600 truncate text-xs mt-0.5">
                  <a class="text-theme-17 dark:text-gray-500 inline-block truncate" href="javascript:">餐飲</a>
                  <span class="mx-1">•</span>{{ review.media.created_at }}
                </div>
              </div>
            </div>
            <div class="relative">
              <img :alt="review.media.caption" :class="{'opacity-75' :review.media.type === 'video'}"
                   v-lazy="review.media.links.image_thumbnail"
                   class="object-fill w-full h-auto"
              >
              <PlayCircleIcon v-if="review.media.type === 'video'"
                              class="absolute top-0 bottom-0 right-0 left-0 m-auto w-12 h-12 text-theme-25" />
            </div>
            <div class="p-4">
              <p class="py-2 text-xs md:text-sm text-description dark:text-gray-400 h-28 overflow-scroll">
                {{ review.media.caption }}
              </p>
              <div class="profile-card-meta bg-white flex flex-col justify-around relative w-full pt-4">
                <div class="profile-card-meta-details flex items-center justify-around">
                  <div class="text-center">
                    <a :href="review.media.links.self" class="block">
                  <span class="block -mb-1 text-sm md:text-lg text-primary-1">
                    {{ $h.abbreviateNumber(review.media.likes_count) }}
                  </span>
                      <span class="text-opacity-50 lg:text-description tracking-wide text-2xs md:text-xs">
                    {{ $t("creator.likes") }}
                  </span>
                    </a>
                  </div>
                  <div class="w-px h-6 bg-gray-400"></div>
                  <div class="text-center">
                    <a :href="review.media.links.self" class="block">
                  <span class="block -mb-1 text-sm md:text-lg text-primary-1">
                    {{ $h.abbreviateNumber(review.media.comments_count) }}
                  </span>
                      <span class="text-opacity-50 lg:text-description tracking-wide text-2xs md:text-xs">
                    {{ $t("creator.comments") }}
                  </span>
                    </a>
                  </div>
                  <div class="w-px h-6 bg-gray-400"></div>
                  <div class="text-center">
                <span class="block -mb-1 text-sm md:text-lg text-primary-1">
                  {{ review.media.engagement_rate }}%
                </span>
                    <span class="text-opacity-50 lg:text-description tracking-wide text-2xs md:text-xs">
                  {{ $t("creator.engagement_rate") }}
                </span>
                  </div>
                </div>
                <a :href="review.media.links.self"
                   class="card-view-button absolute w-full bg-grey-panel font-bold border border-opacity-25 rounded-full py-2 mx-auto left-0 right-0 lg:w-11/12 hover:border-theme-21 hover:text-theme-21 hidden lg:flex lg:items-center lg:justify-center text-sm mb-1" target="_blank">
                  <span>{{ $t("button.view") }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- Pagination -->
        <Pagination v-if="meta.lastPage > 1"
                    :current=meta.currentPage
                    :total=meta.total
                    :perPage=meta.perPage
                    @per-page="meta.perPage = $event"
                    @page-changed="onPaginateReview(props.id, meta.page = current = $event)"
                    :text-before-input="$t('pagination.go_to_page')"
                    :text-after-input="$t('pagination.go')"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, computed, onMounted } from "vue"
import { useRouter, useRoute } from "vue-router"
import useMediaPost from "@/composables/useMediaPost"
import CommonService from "../../services/common.service.js"
import Pagination from '../../components/pagination/Main.vue'
import SmallPagination from '../../components/pagination/SmallPagination.vue'

const props = defineProps({
  'id': {
    type: String,
  },
})

const router = useRouter()
const route = useRoute()
const isLoading = ref(false)
const errorMessage = ref("")
const reviews = ref([])
const overview = ref([])
const meta = reactive({
  page: 1,
  currentPage: 1,
  lastPage: 0,
  total: 0,
  perPage: 10,
})
const filters = reactive({
  page: meta.page,
  limit: 10,
  status: "all",
  categories: 0,
  sort: "-created_at",
  search: null
})

const { onOpenMediaPost } = useMediaPost()

function onPaginateReview(id) {
  onFetchingReviews(id, meta.page)
}

function onFetchingReviews(id, page) {
  isLoading.value = true

  CommonService.fetchOrganizationReviews(id, {
    page: page,
    limit: 1,
    start_date: route.query.startDate,
    end_date: route.query.endDate
  }).then(
    (response) => {
      meta.currentPage = response.meta.current_page
      meta.lastPage = response.meta.last_page
      meta.total = response.meta.total
      meta.perPage = response.meta.per_page
      reviews.value = response.data
      overview.value = response.overview
      isLoading.value = false
    },
    (error) => {
      errorMessage.value =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
    }
  )
}
onMounted(() => onFetchingReviews(props.id, meta.page))

const participantPercentage = computed(() => {
  return ((overview.value.present?.total_campaign_participant - overview.value.past?.total_campaign_participant) / overview.value.past?.total_campaign_participant) * 100;
})

</script>

<style scoped>
body {
  background: white;
}
</style>
