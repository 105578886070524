import DashboardService from "../services/dashboard.service.js"
import { ref } from "vue"
import { helper } from "../utils/helper"
import i18n from "../i18n"

const { t } = i18n.global

export default () => {
  const isLoading = ref(false)
  const isCampaignLoading = ref(false)
  const isReviewLoading = ref(false)
  const isCreatorInfluenceChartLoading = ref(false)
  const isCampaignChartLoading = ref(false)
  const data = ref(null)
  const errorMessage = ref("")
  const campaigns = ref(null)
  const ddlCampaigns = ref(null)
  const reviews = ref(null)
  const campaignChartData = ref(null)
  const creatorInfluenceChartData = ref(null)

  function onFetchingDashboardOverview() {
    isLoading.value = true

    DashboardService.fetchDashboardOverview(
      null
    ).then(
      (response) => {
        data.value = response.data
        isLoading.value = false
      },
      (error) => {
        errorMessage.value =
          (error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.data.message[0]) ||
          error.message ||
          error.toString()
      }
    )
  }

  function onFetchingCampaignAnalysis(campaignId) {
    isCampaignChartLoading.value = true

    DashboardService.fetchCampaignAnalysis(
      campaignId
    ).then(
      (response) => {
        isCampaignChartLoading.value = false
        campaignChartData.value = response.data
      },
      (error) => {
        errorMessage.value =
          (error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.data.message[0]) ||
          error.message ||
          error.toString()
      }
    )
  }

  function onFetchingCreatorInfluenceAnalysis(campaignId, limit) {
    isCreatorInfluenceChartLoading.value = true

    DashboardService.fetchCreatorInfluenceAnalysis(
      { campaign_id: campaignId, limit: limit, }
    ).then(
      (response) => {
        const hash = Object.create(null)
        isCreatorInfluenceChartLoading.value = false
        creatorInfluenceChartData.value = response.data.reduce(function (r, o) {
          if (!hash[o.profile.tier]) {
            hash[o.profile.tier] = {
              name: t(`creator.tier.${o.profile.tier}`),
              level: helper.creator_tier_level(o.profile.tier),
              color: helper.creator_tier_label_color(o.profile.tier),
              data: []
            }
            r.push(hash[o.profile.tier])
          }

          hash[o.profile.tier].data.push({
            name: o.profile.full_name ? o.profile.full_name : o.profile.username,
            username: o.profile.username,
            value: o.engagement_rate * 60,
            engagement_rate: o.engagement_rate,
            follower: helper.abbreviateNumber(o.creator_follower),
            marker: {
              symbol: `url(${ (o.profile.profile_picture_url).replace('tagmonkey.test', 'https://www.tagmonkey.io') })`
            }
          })

          return r
        }, []).sort((a, b) => a.level - b.level)
      },
      (error) => {
        errorMessage.value =
          (error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.data.message[0]) ||
          error.message ||
          error.toString()
      }
    )
  }

  function onFetchingRecentCampaigns() {
    isCampaignLoading.value = true

    DashboardService.fetchRecentCampaigns(
      null
    ).then(
      (response) => {
        campaigns.value = response.data
        isCampaignLoading.value = false
      },
      (error) => {
        errorMessage.value =
          (error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.data.message[0]) ||
          error.message ||
          error.toString()
      }
    )
  }

  function onFetchingRecentReviews() {
    isReviewLoading.value = true

    DashboardService.fetchRecentReviews(
      null
    ).then(
      (response) => {
        reviews.value = response.data
        isReviewLoading.value = false
      },
      (error) => {
        errorMessage.value =
          (error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.data.message[0]) ||
          error.message ||
          error.toString()
      }
    )
  }

  function onFetchingCampaignDropdownList() {
    DashboardService.fetchCampaignDropdownList().then(
      (response) => {
        ddlCampaigns.value = response.data
      },
      (error) => {
        errorMessage.value =
          (error.response &&
            error.response.data &&
            error.response.data.message &&
            error.response.data.message[0]) ||
          error.message ||
          error.toString()
      }
    )
  }

  return {
    isLoading,
    isCampaignLoading,
    isReviewLoading,
    isCreatorInfluenceChartLoading,
    isCampaignChartLoading,
    data,
    campaigns,
    ddlCampaigns,
    reviews,
    creatorInfluenceChartData,
    campaignChartData,
    onFetchingDashboardOverview,
    onFetchingCreatorInfluenceAnalysis,
    onFetchingRecentCampaigns,
    onFetchingRecentReviews,
    onFetchingCampaignDropdownList,
    onFetchingCampaignAnalysis
  }
}
