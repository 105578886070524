import Toastify from "toastify-js"

export default () => {
  const successNotificationToggle = () => {
    Toastify({
      node: cash("#success-notification-content")
        .clone()
        .removeClass("hidden")[0],
      duration: 2000,
      newWindow: true,
      close: true,
      gravity: "top",
      position: "right",
      stopOnFocus: true
    }).showToast();
  }

  const errorNotificationToggle = () => {
    Toastify({
      node: cash("#error-notification-content")
        .clone()
        .removeClass("hidden")[0],
      duration: 2000,
      newWindow: true,
      close: true,
      gravity: "top",
      position: "right",
      stopOnFocus: true
    }).showToast();
  }

  const toastNotification = () => {
    Toastify({
      node: cash('#toast-notification-content')
        .clone()
        .removeClass('hidden')[0],
      duration: 2500,
      newWindow: true,
      close: false,
      gravity: 'top',
      position: 'center',
      stopOnFocus: true
    }).showToast()
  }

  return {
    toastNotification,
    successNotificationToggle
  }
}
