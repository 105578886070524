import { ref, reactive } from "vue"
import { useRoute, useRouter } from "vue-router";
import sortsJson from "@/assets/json/creator-sorts.json"
import categoriesJson from "@/assets/json/categories.json"
import CreatorService from "../services/creator.service"

export default () => {
  const router = useRouter()
  const route = useRoute()
  const id = route.params.id
  const isLoading = ref(false)
  const categories = categoriesJson
  const sorts = sortsJson
  const creators = ref()
  const creator = ref()
  const filters = reactive({
    sort: "-total_follower",
    username: "",
    categories: [0],
    followers: [0, 10],
    engagements: 0,
    format: {
      followers: {
        step: 1,
        min: 0,
        max: 10,
        format: (index) => ["0", "1k", "5k", "10k", "25k", "50k", "100k", "250k", "500k", "750k", "1M+"][index]
      },
      engagements: function (value) {
        return `> ${value}%`
      }
    }
  })
  const meta = reactive({
    page: 1,
    currentPage: 1,
    lastPage: 0,
    total: 0,
    perPage: 24,
  })
  const errorMessage = ref("")

  function onFetchingCreators(page) {
    isLoading.value = true
    CreatorService.fetchCreators(
      {
        page: page,
        limit: meta.perPage,
        sort: filters.sort,
        username: filters.username,
        total_follower: filters.followers,
        engagement_rate: filters.engagements,
        categories: filters.categories.includes(0) ? [] : filters.categories
      }
    ).then(
      (response) => {
        meta.currentPage = response.meta.current_page
        meta.lastPage = response.meta.last_page
        meta.total = response.meta.total
        meta.perPage = response.meta.per_page
        creators.value = response.data
        isLoading.value = false
      },
      (error) => {
        errorMessage.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
      }
    )
  }

  function onCreatorProfile(path) {
    router.push(path)
  }

  function onPaginate() {
    onFetchingCreators(meta.page)
  }

  function onSort() {
    onFetchingCreators(1)
  }

  function onCategoriesFilter(value) {
    if(filters.categories.includes(value)) {
      filters.categories = filters.categories.filter(category => category !== value)
    } else {
      const removeIndex = filters.categories.indexOf(0)
      if (removeIndex !== -1) {
        filters.categories.splice(removeIndex, 1)
      }

      filters.categories.push(value)
    }

    if(filters.categories.length < 1 || value === 0) {
      filters.categories = [0]
    }

    onFetchingCreators(1)
  }

  function onProfile() {
    CreatorService.fetchCreator(id).then(
      (response) => {
        creator.value = response.data
        isLoading.value = false
      },
      (error) => {
        errorMessage.value =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
      }
    )
  }

  function onMediaPost(url) {
    const width = 1000
    const height = 720
    const left = (screen.width / 2) - (width / 2)
    const top = (screen.height / 2) - (height / 2)
    return window.open(
      url,
      creator.value.full_name,
      "toolbar=no, location=no, directories=no, status=no, menubar=no, resizable=no, " +
      "copyhistory=no, width=" + width + ", height=" + height + ", top=" + top + ", left=" + left
    )
  }

  function redirectToCreatorProfile($path) {
    window.open($path, '_blank');
  }


  return {
    isLoading,
    id,
    categories,
    sorts,
    creator,
    creators,
    filters,
    meta,
    errorMessage,
    onFetchingCreators,
    onCreatorProfile,
    onPaginate,
    onSort,
    onCategoriesFilter,
    onProfile,
    onMediaPost,
    redirectToCreatorProfile,
  }
}
