import { ref } from "vue";
import UploadService from "../services/upload.service.js"

export default () => {

  const fileUrl = ref(null)
  const filePath = ref(null)

  function handleImage(e) {
    const selectedImage = e.target.files[0]
    const formData = new FormData();

    if (!selectedImage) {
      return;
    }
    formData.append('file', selectedImage);

    UploadService.uploadFileAsTemp(formData).then(
      (response) => {
        fileUrl.value = response.url
        filePath.value = response.filename
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()

        alert(message)
      }
    )
  }

  return {
    fileUrl,
    filePath,
    handleImage
  }
}
