<template>
  <!-- BEGIN: Top Bar -->
  <div
    class="top-bar-boxed border-b border-transparent mb-2 -mt-7 md:-mt-5 -mx-3 sm:mb-6 sm:-mx-8 px-3 sm:px-8 md:pt-0"
  >
    <div class="h-full flex items-center justify-between">
      <!-- BEGIN: Logo -->
      <div class="flex items-center gap-4">
      <a href="" class="-intro-x hidden md:flex">
        <img
          :alt="$t('app.title')"
          class="w-6"
          src="@/assets/images/logo.jpeg"
        />
        <span class="text-white text-lg ml-3">
          {{ appName }}
        </span>
      </a>
      <!-- END: Logo -->
      <!-- BEGIN: Breadcrumb -->
      <!-- END: Breadcrumb -->
      <!-- BEGIN: Search -->
      <div class="intro-x hidden md:block relative mr-3 sm:mr-6">
        <div class="dropdown w-full md:w-72" style="position: relative;">
<!--          <fontawesome-icon icon="exchange-alt"-->
<!--                            class="block md:hidden dropdown-toggle text-base text-white"-->
<!--                            role="button"-->
<!--                            aria-expanded="false"-->
<!--          />-->
          <div class="organization-dropdown-toggle dropdown-toggle btn w-full flex items-center justify-start border-dashed border-gray-500 bg-theme-25 dark:bg-dark-2 dark:border-dark-2"
               role="button"
               aria-expanded="false">
            <div class="w-6 h-6 image-fit mr-3">
              <img class="rounded"
                   :alt="selectedOrganization.name"
                   v-lazy="selectedOrganization.logo">
            </div>
            <div class="truncate">{{ selectedOrganization.name }}</div>
            <chevron-down-icon class="w-5 h-5 ml-auto"/>
          </div>
          <div class="dropdown-menu w-full">
            <div class="dropdown-menu__content box dark:bg-dark-1 p-2">
              <a v-for="(organization, key) in organizations"
                 :key="key"
                 href="javascript:;"
                 class="flex items-center block p-2 transition duration-300 ease-in-out dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md mb-0.5"
                 :class="(organization.id === selectedOrganization.id) ? 'bg-theme-25' : 'bg-white'"
                 @click="onSwitchOrganization(organization.id)"
              >
                <div class="w-6 h-6 absolute image-fit mr-3">
                  <img class="rounded"
                       :alt="organization.name"
                       v-lazy="organization.logo">
                </div>
                <div class="ml-8 pl-1 truncate">{{ organization.name }}</div>
              </a>
            </div>
          </div>
        </div>
      </div>
      </div>
      <!-- END: Search -->

      <!-- BEGIN: Account Menu -->
      <a
        @click.prevent="handleLogOut"
        class="flex items-center block p-2 px-4 transition duration-300 ease-in-out bg-primary-7 hover:bg-opacity-80 rounded-md cursor-pointer"
      >
        <ToggleRightIcon class="w-4 h-4 mr-2" />
        {{ $t('button.logout') }}
      </a>
      <!-- END: Account Menu -->
    </div>
  </div>
  <!-- END: Top Bar -->
</template>

<script setup>
import { ref } from 'vue'
import { useStore } from "@/store";
import { useRouter } from "vue-router";
import useMain from "../../composables/useMain.js"

const store = useStore();
const router = useRouter();
const { onSwitchOrganization } = useMain()
const selectedOrganization = JSON.parse(localStorage.getItem('organizations')).find(organization => organization.id === localStorage.getItem('tenant'))
const organizations = JSON.parse(localStorage.getItem('organizations'))

const searchDropdown = ref(false)

const showSearchDropdown = () => {
  searchDropdown.value = true
}

const appName = process.env.VUE_APP_NAME;


const hideSearchDropdown = () => {
  searchDropdown.value = false
}

function handleLogOut() {
  cash('.dropdown-menu').removeClass('show')
  store.dispatch('auth/logout');
  router.push('/login');
}
</script>
