<template>
  <div class="grid grid-cols-12 gap-6 px-0 md:px-20">
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-6">
        <!-- BEGIN: General Report -->
        <div class="col-span-12 mt-8">
          <div class="intro-y items-center justify-between mb-14 flex-wrap">
            <!-- BEGIN: Welcome Message -->
<!--            <div class="w-full md:w-auto">-->
<!--              <div>-->
<!--                <h2 class="text-2xl font-semibold truncate mr-8">-->
<!--                  <span class="text-description font-normal text-2xl">{{ $t('app.hello') }}</span>-->
<!--                  {{ selectedOrganization.name }}-->
<!--                </h2>-->
<!--                <div class="flex items-center gap-6 mt-2">-->
<!--                  <fontawesome-icon icon="exchange-alt" class="text-lg" />-->
<!--                  <div class="dropdown w-full" style="position: relative;">-->
<!--                    <div class="organization-dropdown-toggle dropdown-toggle btn w-full flex items-center justify-start border-dashed border-gray-500 bg-theme-25 dark:bg-dark-2 dark:border-dark-2"-->
<!--                         role="button"-->
<!--                         aria-expanded="false">-->
<!--                      <div class="w-6 h-6 image-fit mr-3">-->
<!--                        <img class="rounded"-->
<!--                             :alt="selectedOrganization.name"-->
<!--                             v-lazy="selectedOrganization.logo">-->
<!--                      </div>-->
<!--                      <div class="truncate">{{ selectedOrganization.name }}</div>-->
<!--                      <chevron-down-icon class="w-5 h-5 ml-auto"/>-->
<!--                    </div>-->
<!--                    <div class="dropdown-menu w-full" id="_fb9ae4rqa">-->
<!--                      <div class="dropdown-menu__content box dark:bg-dark-1 p-2">-->
<!--                        <a v-for="(organization, key) in organizations"-->
<!--                           :key="key"-->
<!--                           href="javascript:;"-->
<!--                           class="flex items-center block p-2 transition duration-300 ease-in-out dark:bg-dark-1 hover:bg-gray-200 dark:hover:bg-dark-2 rounded-md mb-0.5"-->
<!--                           :class="(organization.id === selectedOrganization.id) ? 'bg-gray-200' : 'bg-white'"-->
<!--                           @click="onSwitchOrganization(organization.id)"-->
<!--                        >-->
<!--                          <div class="w-6 h-6 absolute image-fit mr-3">-->
<!--                            <img class="rounded"-->
<!--                                 :alt="organization.name"-->
<!--                                 v-lazy="organization.logo">-->
<!--                          </div>-->
<!--                          <div class="ml-8 pl-1 truncate">{{ organization.name }}</div>-->
<!--                        </a>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="w-auto md:w-72 box p-3 my-6 bg-theme-45 rounded-full">-->
<!--                <div class="flex flex-wrap items-center gap-3">-->
<!--                  <div class="mr-auto">-->
<!--                    <div class="relative text-lg md:text-2xl font-semibold leading-5 pl-3.5">-->
<!--                      {{ $t('button.create_campaign') }}-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <a class="flex items-center justify-center w-12 h-12 rounded-full bg-theme-36 dark:bg-dark-1 hover:bg-opacity-90"-->
<!--                     href="javascript:"-->
<!--                     @click="onCreateCampaign"-->
<!--                  >-->
<!--                    <PlusIcon class="w-6 h-6" />-->
<!--                  </a>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
            <!-- END: Welcome Message -->
            <!-- BEGIN: Overview -->
            <div>
              <div class="intro-y flex items-center h-10">
                <h2 class="text-lg font-semibold truncate mr-5">{{ $t('dashboard.analytics_overview') }}</h2>
                <a href=""
                   class="ml-auto flex items-center text-theme-26 dark:text-theme-33"
                >
                  <RefreshCcwIcon class="w-4 h-4 mr-3" />
                  <span class="hidden md:block">{{ $t('button.reload_data') }}</span>
                </a>
              </div>
              <div class="grid grid-cols-12 gap-6 mt-2 w-full md:w-auto">
                <!-- Active Campaign -->
                <div class="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
                  <div class="report-box zoom-in">
                    <div class="box p-5">
                      <div class="flex">
                        <Volume2Icon class="report-box__icon text-theme-21" />
                      </div>
                      <div class="text-3xl font-bold leading-8 mt-6">
                        {{ data?.campaign_count }}
                      </div>
                      <div class="text-base text-gray-600 mt-1">{{ $t('dashboard.active_campaign') }}</div>
                    </div>
                  </div>
                </div>
                <!-- Total Campaign Participants -->
                <div class="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
                  <div class="report-box zoom-in">
                    <div class="box p-5">
                      <div class="flex">
                        <UsersIcon class="report-box__icon text-theme-22" />
                      </div>
                      <div class="text-3xl font-bold leading-8 mt-6">
                        {{ data?.total_campaign_participant }}
                      </div>
                      <div class="text-base text-gray-600 mt-1">
                        {{ $t('dashboard.total_campaign_participant') }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Average of Follower -->
                <div class="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
                  <div class="report-box zoom-in">
                    <div class="box p-5">
                      <div class="flex">
                        <UsersIcon class="report-box__icon text-theme-23" />
                      </div>
                      <div class="text-3xl font-bold leading-8 mt-6">
                        {{ $h.abbreviateNumber(data?.total_follower) }}
                        <span class="text-sm text-gray-500">
                      </span>
                      </div>
                      <div class="text-base text-gray-600 mt-1">
                        {{ $t('dashboard.average_of_follower') }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Total Reach -->
                <div class="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
                  <div class="report-box zoom-in">
                    <div class="box p-5">
                      <div class="flex">
                        <BarChart2Icon class="report-box__icon text-theme-10" />
<!--                        <div class="ml-auto">-->
<!--                          <Tippy-->
<!--                            tag="div"-->
<!--                            class="report-box__indicator bg-theme-10 cursor-pointer"-->
<!--                            content="22% Higher than last month"-->
<!--                          >-->
<!--                            22% <ChevronUpIcon class="w-4 h-4" />-->
<!--                          </Tippy>-->
<!--                        </div>-->
                      </div>
                      <div class="text-3xl font-bold leading-8 mt-6">
                        {{ $h.abbreviateNumber(data?.total_reach) }}
                      </div>
                      <div class="text-base text-gray-600 mt-1">
                        {{ $t('dashboard.total_reach') }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Average Engagement -->
                <div class="col-span-12 sm:col-span-6 xl:col-span-2 intro-y">
                  <div class="report-box zoom-in">
                    <div class="box p-5">
                      <div class="flex">
                        <BarChart2Icon class="report-box__icon text-theme-10" />
<!--                        <div class="ml-auto">-->
<!--                          <Tippy-->
<!--                            tag="div"-->
<!--                            class="report-box__indicator bg-theme-10 cursor-pointer"-->
<!--                            content="22% Higher than last month"-->
<!--                          >-->
<!--                            22% <ChevronUpIcon class="w-4 h-4" />-->
<!--                          </Tippy>-->
<!--                        </div>-->
                      </div>
                      <div class="text-3xl font-bold leading-8 mt-6">
                        {{ $h.abbreviateNumber(data?.total_engagement) }}%
                      </div>
                      <div class="text-base text-gray-600 mt-1">
                        {{ $t('dashboard.average_engagement') }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Listing Product -->
<!--                <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">-->
<!--                  <div class="report-box zoom-in">-->
<!--                    <div class="box p-5">-->
<!--                      <div class="flex">-->
<!--                        <PackageIcon class="report-box__icon text-theme-10" />-->
<!--                      </div>-->
<!--                      <div class="text-3xl font-bold leading-8 mt-6">-->
<!--                        {{ $h.abbreviateNumber(data?.total_item) }}-->
<!--                      </div>-->
<!--                      <div class="text-base text-gray-600 mt-1">-->
<!--                        {{ $t('dashboard.listing_product') }}-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
                <!-- Sales Generate from creator commerce -->
<!--                <div class="col-span-12 sm:col-span-6 xl:col-span-3 intro-y">-->
<!--                  <div class="report-box zoom-in">-->
<!--                    <div class="box p-5">-->
<!--                      <div class="flex">-->
<!--                        <ShoppingCartIcon class="report-box__icon text-theme-10" />-->
<!--                      </div>-->
<!--                      <div class="text-3xl font-bold leading-8 mt-6">-->
<!--                        HK${{ $h.abbreviateNumber(data?.total_sale) }}-->
<!--                      </div>-->
<!--                      <div class="text-base text-gray-600 mt-1">-->
<!--                        {{ $t('dashboard.sales_from_creator') }}-->
<!--                      </div>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </div>-->
              </div>
            </div>
            <!-- END: Overview -->
          </div>


          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <!-- BEGIN: Chart -->
            <div class="px-10 sm:px-28 md:px-5 -mx-5 mt-5">
              <div class="p-5 bg-white rounded-lg">
                <Skeletor height="200" class="rounded-md mb-10"
                          v-if="!ddlCampaigns" />
                <campaign-engagement-chart v-if="ddlCampaigns" :campaigns="ddlCampaigns" />
              </div>
            </div>
            <div class="px-10 sm:px-28 md:px-5 -mx-5 mt-5">
              <div class="p-5 bg-white rounded-lg">
                <Skeletor height="200" class="rounded-md mb-10"
                          v-if="!ddlCampaigns" />
                <creator-engagement-chart v-if="ddlCampaigns" :campaigns="ddlCampaigns" />
              </div>
            </div>
            <!-- END: Chart -->
          </div>

          <!-- BEGIN: Recent Campaign -->
          <div class="intro-y">
            <div class="col-span-12 flex items-center h-10 mt-10">
              <h2 class="text-lg font-semibold truncate mr-5">{{ $t('dashboard.recent_campaign') }}</h2>
              <a href="javascript:"
                 class="ml-auto flex items-center text-theme-26 dark:text-theme-33"
                 @click="onGoToCampaignList"
              >
                <span class="hidden md:block">{{ $t('button.check_all') }}</span>
              </a>
            </div>
            <campaign-listing-summary :campaigns="campaigns"
                                      :isCampaignLoading="isCampaignLoading"
            />
          </div>
          <!-- END: Recent Campaign -->

          <!-- BEGIN: Participant Overview -->
          <!-- TODO: Add participant overview -->
          <!-- END: Participant Overview -->

          <!-- BEGIN: Recent Review -->
          <div class="intro-y">
            <div class="flex items-center h-10 mt-5 mb-2">
              <h2 class="text-lg font-semibold truncate mr-5">{{ $t('dashboard.recent_review') }}</h2>
            </div>

            <creator-review :reviews="reviews"
                            :isReviewLoading="isReviewLoading"
                            :xlCols=4 />
          </div>
          <!-- END: Recent Review -->
          <!-- BEGIN: Recent Sale -->
          <!-- TODO: Add recent sale -->
          <!-- END: Recent Sale -->
        </div>
        <!-- END: General Report -->
      </div>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from "vue"
import { useRouter } from "vue-router"
import CampaignEngagementChart from '../../components/dashboard/CampaignEngagementChart.vue'
import CreatorEngagementChart from '../../components/dashboard/CreatorEngagementChart.vue'
import CampaignListingSummary from "../../components/campaign/listing/CampaignListingSummary.vue"
import CreatorReview from "../../components/creator/CreatorReview.vue"
import useMain from "../../composables/useMain.js"
import useMediaPost from "../../composables/useMediaPost"
import useCampaign from "../../composables/useCampaign.js"
import useDashboard from "../../composables/useDashboard.js"
import useCreatorDatabase from "../../composables/useCreatorDatabase.js"

const router = useRouter()
const { onOpenMediaPost } = useMediaPost()
const { onCreatorProfile } = useCreatorDatabase()
const { onSwitchOrganization } = useMain()
const { onCreateCampaign } = useCampaign()
const {
  isLoading,
  isCampaignLoading,
  isReviewLoading,
  data,
  campaigns,
  ddlCampaigns,
  reviews,
  onFetchingDashboardOverview,
  onFetchingCreatorInfluenceAnalysis,
  onFetchingRecentCampaigns,
  onFetchingRecentReviews,
  onFetchingCampaignDropdownList
} = useDashboard()
const selectedOrganization = JSON.parse(localStorage.getItem('organizations')).find(organization => organization.id === localStorage.getItem('tenant'))
const organizations = JSON.parse(localStorage.getItem('organizations'))

onMounted(() => {
  onFetchingDashboardOverview()
  onFetchingRecentCampaigns()
  onFetchingRecentReviews()
  onFetchingCampaignDropdownList()
})

function onGoToCampaignList() {
  router.push('/campaigns')
}

</script>
<style lang="scss">

.organization-dropdown-toggle {
  svg {
    transition: .15s;
  }
}
.organization-dropdown-toggle[aria-expanded="true"] {
  svg {
    transform: rotate(180deg);
  }
}
</style>
