<template>
  <div class="flex flex-wrap justify-center mt-8 gap-2">
    <a
      :class="{'hover:border-theme-21 hover:text-theme-21 text-primary-1 text-opacity-100': hasPrev() }"
      class="w-24 h-8 flex items-center justify-center p-4 text-xs leading-4 border rounded-full font-semibold border-grey-light border-solid text-primary-1 text-opacity-40 mr-3"
      href="#"
      @click.prevent="changePage(prevPage)"
    >
      <span>{{ $t('pagination.previous') }}</span>
    </a>

    <a
      v-if="hasFirst()"
      class="w-8 h-8 flex items-center justify-center font-semibold mb-1 p-4 md:p-2 text-xs leading-4 border border-solid rounded border-grey-light text-primary-1"
      disabled="true"
      href="">
      <span>...</span>
    </a>

    <a
      v-for="(page, index) in pages" :key="index"
      :class="{'border-theme-21 text-theme-21': current === page}"
      class="w-8 h-8 flex items-center justify-center font-semibold mb-1 p-4 md:p-2 text-xs leading-4 border border-solid rounded border-grey-light text-primary-1 hidden md:flex hover:text-theme-21 hover:border-theme-21"
      disabled="false"
      href="#" @click.prevent="changePage(page)">
      <span>{{ page }}</span>
    </a>

    <a
      v-if="hasLast()"
      class="w-8 h-8 flex items-center justify-center font-semibold mb-1 p-4 md:p-2 text-xs leading-4 border border-solid rounded border-grey-light text-primary-1"
      disabled="true"
      href="">
      <span>...</span>
    </a>

    <a
      v-if="hasLast()"
      @click.prevent="changePage(totalPages)"
      class="w-8 h-8 flex items-center justify-center font-semibold mb-1 p-4 md:p-2 text-xs leading-4 border border-solid rounded border-grey-light text-grey-dark" disabled="true" href="">
      <span>{{ totalPages }}</span>
    </a>
    <a
      :class="{'hover:border-theme-21 hover:text-theme-21 text-opacity-100': hasNext() }"
      class="w-24 h-8 flex items-center justify-center p-4 text-xs leading-4 border rounded-full font-semibold border-grey-light border-solid text-primary-1 text-opacity-40 ml-3"
      disabled="false"
      href="#"
      @click.prevent="changePage(nextPage)"
    >
      <span>{{ $t('pagination.next') }}</span>
    </a>
  </div>
</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'Pagination',
  props: {
    current: {
      type: Number,
      default: 1
    },
    total: {
      type: Number,
      default: 0
    },
    perPage: {
      type: Number,
      default: 20
    },
    pageRange: {
      type: Number,
      default: 5
    },
    textBeforeInput: {
      type: String,
      default: "Go to page"
    },
    textAfterInput: {
      type: String,
      default: "Go"
    }
  },
  data() {
    return {
      input: '',
      totalPerPage: this.perPage,
    }
  },
  methods: {
    hasFirst: function () {
      return this.rangeStart !== 1
    },
    hasLast: function () {
      return this.rangeEnd < this.totalPages
    },
    hasPrev: function () {
      return this.current > 1
    },
    hasNext: function () {
      return this.current < this.totalPages
    },
    changePage: function (page) {
      if (page > 0 && page <= this.totalPages) {
        this.$emit('page-changed', page)
      }
    },
    changePerPage: function () {
      this.input = ''
      this.$emit('per-page', this.totalPerPage)
    }
  },
  computed: {
    pages: function () {
      const pages = []
      for (let i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i)
      }

      return pages
    },
    rangeStart: function () {
      const start = this.current - this.pageRange

      return (start > 0) ? start : 1
    },
    rangeEnd: function () {
      const end = this.current + this.pageRange

      return (end < this.totalPages) ? end : this.totalPages
    },
    totalPages: function () {
      return Math.ceil(this.total / this.perPage)
    },
    nextPage: function () {
      return this.current + 1
    },
    prevPage: function () {
      return this.current - 1
    }
  }
})
</script>
