// import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
//
// export const SUPPORT_LOCALES = ['en', 'zh_hk']
//
// export function setupI18n(options = { locale: process.env.VUE_APP_I18N_LOCALE }) {
//   const i18n = createI18n(options)
//   setI18nLanguage(i18n, options.locale)
//
//   console.log(i18n.global.locale)
//   return i18n
// }
//
// export function setI18nLanguage(i18n, locale) {
//   if (i18n.mode === 'legacy') {
//     i18n.global.locale = locale
//   } else {
//     i18n.global.locale.value = locale
//   }
//   /**
//    * NOTE:
//    * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
//    * The following is an example for axios.
//    *
//    * axios.defaults.headers.common['Accept-Language'] = locale
//    */
//   document.querySelector('html').setAttribute('lang', locale)
// }
//
// export async function loadLocaleMessages(i18n, locale) {
//   // load locale messages with dynamic import
//   const messages = await import(
//     /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.json`
//     )
//
//   // set locale and locale message
//   i18n.global.setLocaleMessage(locale, messages.default)
//
//   return nextTick()
// }

function loadLocalMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1].replace('-', '_')
      messages[locale] = locales(key)
    }
  })

  return messages;
}

const i18n = createI18n({
  silentTranslationWarn: true,
  locale: 'zh_hk',
  fallbackLocale: 'en',
  messages: loadLocalMessages(),
});

export default i18n
